import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  editAllAccountDetails,
} from "../actions/AccountsActions";
import "../styles/App.css";

function Profile() {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);

  const [userData, setUserData] = useState(user);
  const [editMode, setEditMode] = useState(false);
  const [editPass, setEditPass] = useState(false);
  const [password, setPassword] = useState({
    oldpass: null,
    newpass: null,
    confirmpass: null,
  });
  const [passwordError, setPasswordError] = useState();

  return (
    <div className="profile-panel">
      {editPass ? (
        <div className="change-pass-modal">
          <div className="modal">
            <div className="head">Change Password</div>
            {passwordError ? (
              <div className="error">{passwordError}</div>
            ) : null}
            <div className="body">
              <div className="input-group">
                <p className="label">Current Password</p>
                <input
                  type="password"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      oldpass: e.target.value,
                    })
                  }
                />
              </div>

              <div className="input-group">
                <p className="label">New Password</p>
                <input
                  type="password"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      newpass: e.target.value,
                    })
                  }
                />
              </div>

              <div className="input-group">
                <p className="label">Confirm Password</p>
                <input
                  type="password"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confirmpass: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="action-btns">
              <div className="btn cancel" onClick={() => setEditPass(false)}>
                Cancel
              </div>
              <div
                className="btn"
                onClick={async () => {
                  try {
                    if (
                      password.newpass === password.confirmpass &&
                      password.newpass !== null &&
                      password.confirmpass !== null
                    ) {
                      await dispatch(
                        changePassword(
                          user.id,
                          password.oldpass,
                          password.newpass
                        )
                      );
                      setEditPass(false);
                      setPasswordError();
                    } else {
                      setPasswordError(
                        "New password doesn't match with confirm password input"
                      );
                    }
                  } catch (error) {
                    setPasswordError(error.response.data.data);
                  }
                }}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="user-profile">
        <h2>Profile Settings</h2>
        <div className="inputs">
          <div className="left">
            <div className="input-group">
              <p className="label">Username:</p>
              {editMode ? (
                <input type="text" defaultValue={userData.username} disabled />
              ) : (
                <p className="value">{userData.username}</p>
              )}
            </div>
            <div className="input-group">
              <p className="label">Company:</p>
              {editMode ? (
                <input
                  type="text"
                  defaultValue={userData.company_name}
                  disabled
                />
              ) : (
                <p className="value">{userData.company_name}</p>
              )}
            </div>
            <div className="input-group">
              <p className="label">Role:</p>
              {editMode ? (
                <input
                  type="text"
                  defaultValue={userData.account_permissions.permission_name}
                  disabled
                />
              ) : (
                <p className="value">
                  {userData.account_permissions.permission_name}
                </p>
              )}
            </div>
          </div>
          <div className="right">
            <div className="input-group">
              <p className="label">Email:</p>
              {editMode ? (
                <input
                  type="text"
                  defaultValue={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              ) : (
                <p className="value">{userData.email}</p>
              )}
            </div>
            <div className="input-group">
              <p className="label">Name:</p>
              {editMode ? (
                <input
                  type="text"
                  defaultValue={userData.name}
                  onChange={(e) =>
                    setUserData({ ...userData, name: e.target.value })
                  }
                />
              ) : (
                <p className="value">{userData.name}</p>
              )}
            </div>
            <div className="input-group">
              <p className="label">Phone Number:</p>
              {editMode ? (
                <input
                  type="text"
                  defaultValue={userData.phone_number}
                  onChange={(e) =>
                    setUserData({ ...userData, phone_number: e.target.value })
                  }
                />
              ) : (
                <p className="value">{userData.phone_number}</p>
              )}
            </div>
          </div>
        </div>

        <div className="action-btns">
          {editMode ? (
            <div
              className="btn cancel"
              onClick={() => {
                setEditMode(false);
                setUserData(user);
              }}
            >
              Cancel
            </div>
          ) : (
            <div className="btn" onClick={() => setEditPass(true)}>
              Change password
            </div>
          )}
          {editMode ? (
            <div
              className="btn"
              onClick={async () => {
                await dispatch(
                  editAllAccountDetails(user.id, {
                    name: userData.name,
                    email: userData.email,
                    phone_number: userData.phone_number,
                  })
                );
                setEditMode(false);
                localStorage.setItem("user", JSON.stringify(userData));
              }}
            >
              Update profile
            </div>
          ) : (
            <div className="btn" onClick={() => setEditMode(true)}>
              Edit profile
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
