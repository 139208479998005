import React from "react";
import "../styles/App.css";

function Docs() {
  return (
    <div className="docs-panel">
      <iframe src="https://docs.baetes.com/" title="docs"></iframe>
    </div>
  );
}

export default Docs;
