import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../../styles/App.css";
import logo from "../../images/baetes_white.png";
import { login } from "../../actions/UserActions";

function Login() {
  const dispatch = useDispatch();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState();

  return (
    <div className="login-overlay active">
      <img src={logo} alt="" />
      <div className="login-widget">
        <div className="head">
          <p>LOGIN</p>
        </div>
        {errorMsg ? <div className="error-area">{errorMsg}</div> : null}
        <div className="input-group username-inp">
          <input
            type="text"
            placeholder="Enter Username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div
          className="login-btn"
          onClick={async () => {
            try {
              await dispatch(login({ username, password }));
              setErrorMsg();
            } catch (error) {
              setErrorMsg(error?.response?.data.message);
            }
          }}
        >
          LOGIN
        </div>
        {/* <a href="" className="forgot-pass">
          Forgot Password?
        </a> */}
      </div>
    </div>
  );
}

export default Login;
