import React, { useState, useCallback } from "react";
import "../../styles/App.css";
import Tile from "./Tiles";
import AnimateHeight from "react-animate-height";
import { useDropzone } from "react-dropzone";

function Mesh({
  mesh,
  updateMesh,
  updateMeshName,
  minimizedMeshWindow,
  setMinimizedMeshWindow,
  addAlbedo,
  addMaterial,
  addTexture,
  deleteMesh,
  deleteAlbedo,
  deleteMaterial,
  deleteTexture,
  setEditorMode,
  editorMode,
  closeEditor,
  partIndex,
  meshIndex,
  updateAlbedo,
  updateAlbedoName,
  updateAlbedoType,
  updateTexture,
  updateTextureName
}) {
  const [fileUploaded, setFileUploaded] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
        updateMesh(file.name, binaryStr);
        setFileUploaded(file.name);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 25000000 // in bytes
  });

  return (
    <AnimateHeight
      className="mesh"
      height={minimizedMeshWindow.includes(mesh.name) ? 70 : "auto"}
      duration={500}
    >
      <div className="head">
        <p className="title">
          {editorMode.meshIndex === meshIndex &&
          editorMode.partIndex === partIndex &&
          editorMode.field === "mesh" ? (
            <div className="editor">
              <input
                type="text"
                value={editorMode.inputValue ?? mesh.name}
                onChange={(e) =>
                  e.target.value.replace(/[a-zA-Z0-9\s]+/g, "") === ""
                    ? setEditorMode({
                        ...editorMode,
                        inputValue: e.target.value
                      })
                    : null
                }
              />
              <i
                className="fas fa-times"
                onClick={() => {
                  closeEditor();
                }}
              ></i>
              <i
                className="fas fa-check"
                onClick={() => {
                  updateMeshName(editorMode.inputValue);
                  closeEditor();
                }}
              ></i>
            </div>
          ) : (
            <div className="view">
              {mesh.name}{" "}
              <i
                className="fas fa-pen"
                onClick={() =>
                  setEditorMode({
                    ...editorMode,
                    partIndex: partIndex,
                    meshIndex: meshIndex,
                    field: "mesh"
                  })
                }
              ></i>
            </div>
          )}
        </p>
        <div className="window-actions">
          {minimizedMeshWindow.includes(mesh.name) ? (
            <i
              className="fas fa-plus"
              title="Maximize"
              onClick={() =>
                setMinimizedMeshWindow(
                  minimizedMeshWindow.filter((entry) => entry !== mesh.name)
                )
              }
            ></i>
          ) : (
            <i
              className="fas fa-minus"
              title="Minimize"
              onClick={() =>
                setMinimizedMeshWindow([...minimizedMeshWindow, mesh.name])
              }
            ></i>
          )}
          {meshIndex === 0 ? null : (
            <i
              className="fas fa-times delete-entry"
              onClick={() => deleteMesh(partIndex, meshIndex)}
              title="Delete"
            ></i>
          )}
        </div>
      </div>
      <div className="drag-drop-zone">
        <div
          {...getRootProps({
            className: "drop-area " + (fileUploaded ? "activated" : null)
          })}
        >
          <input {...getInputProps()} />
          {fileUploaded ? (
            <p>Uploaded {fileUploaded} Successfully!</p>
          ) : (
            <p>Drop .obj Mesh Here</p>
          )}
        </div>
      </div>
      <div className="material">
        <div className="head">
          <p className="title">
            <div className="view">Albedos</div>
          </p>
        </div>
        <div className="available-texture-list">
          <div
            className="texture"
            onClick={() => addAlbedo(meshIndex, partIndex)}
          >
            <i className="fas fa-plus"></i>
            <p>Add Albedo</p>
          </div>
        </div>
        <div className="picked-textures">
          {mesh.albedos.map((albedo, albedoIndex) => (
            <Tile
              deleteTile={() => deleteAlbedo(meshIndex, partIndex, albedo.name)}
              name={albedo.name}
              key={albedo.id}
              currentColor={albedo.color ? albedo.color : "#000"}
              nameEdit={true}
              albedoType={albedo.type}
              updateAlbedoType={(albedoType) =>
                updateAlbedoType(albedoIndex, albedoType)
              }
              type="Albedo"
              updateAlbedo={(filename, base64, hex) =>
                updateAlbedo(albedoIndex, filename, base64, hex)
              }
              updateAlbedoName={(name) => updateAlbedoName(albedoIndex, name)}
            />
          ))}
        </div>
      </div>
      {mesh.materials.map((material, key) => (
        <div className="material">
          <div className="head">
            <p className="title">
              {editorMode.materialIndex === key &&
              editorMode.partIndex === meshIndex &&
              editorMode.meshIndex === partIndex &&
              editorMode.field === "material" ? (
                <div className="editor">
                  <input type="text" />
                  <i className="fas fa-times" onClick={() => closeEditor()}></i>
                  <i className="fas fa-check" onClick={() => closeEditor()}></i>
                </div>
              ) : (
                <div className="view">
                  {material.name}{" "}
                  {key === 0 ? null : (
                    <i
                      className="fas fa-pen"
                      onClick={() =>
                        setEditorMode({
                          ...editorMode,
                          partIndex: partIndex,
                          meshIndex: meshIndex,
                          materialIndex: key,
                          field: "material"
                        })
                      }
                    ></i>
                  )}
                </div>
              )}
            </p>
            {key === 0 ? null : (
              <i
                className="fas fa-times delete-entry"
                onClick={() => deleteMaterial(meshIndex, partIndex, key)}
              ></i>
            )}
          </div>
          <div className="available-texture-list">
            {material.availableTextures.map((texture, index) => (
              <div
                className="texture"
                onClick={() => addTexture(meshIndex, partIndex, key, texture)}
                key={texture}
              >
                <i className="fas fa-plus"></i>
                <p>{texture}</p>
              </div>
            ))}
          </div>
          <div className="picked-textures">
            {material.selectedTextures.map((texture, textureIndex) => (
              <Tile
                deleteTile={() =>
                  deleteTexture(meshIndex, partIndex, key, texture.name)
                }
                textureType={texture.type}
                name={texture.name}
                key={texture.id}
                nameEdit={false}
                type="Texture"
                updateTexture={(filename, base64) =>
                  updateTexture(key, textureIndex, filename, base64)
                }
                updateTextureName={(name) =>
                  updateTextureName(key, textureIndex, name)
                }
              />
            ))}
          </div>
        </div>
      ))}
      <div
        className="add-btn"
        onClick={() => addMaterial(meshIndex, partIndex)}
      >
        <i className="fas fa-plus"></i>
        <p>Add Material</p>
      </div>
    </AnimateHeight>
  );
}

export default Mesh;
