import React, { useState } from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "../../../../../styles/Slider.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const SimpleSlider = createSliderWithTooltip(Slider);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip>
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

function ModelScreen({ showOpenSetting, currentSettings, updateViewer }) {
  const [rotation, setRotation] = useState({
    x: currentSettings.model.rotation.x,
    y: currentSettings.model.rotation.y,
    z: currentSettings.model.rotation.z,
  });
  const [scale, setScale] = useState(currentSettings.model.scale);

  return (
    <div className={"active-setting-panel " + (showOpenSetting ? "show" : "")}>
      <div className="setting-entry">
        <p className="label">Scale</p>
        <SimpleSlider
          min={0}
          max={100}
          step={1}
          marks={{
            0: "0",
            100: "100",
          }}
          defaultValue={[scale]}
          onChange={(value) => {
            setScale(value);
            updateViewer("scale", value);
          }}
          handle={handle}
          style={{ marginBottom: 60 }}
        />
      </div>

      <div className="setting-entry">
        <p className="label">Rotation</p>
        <div className="xyz-inputs">
          <div className="group">
            <p>X</p>
            <Range
              min={0}
              max={6.28}
              step={0.01}
              marks={{
                0: "0",
                6.28: "2π",
              }}
              value={[rotation.x]}
              onChange={(e) => {
                setRotation({
                  ...rotation,
                  x: parseFloat(e),
                });

                updateViewer("rotation", {
                  ...rotation,
                  x: parseFloat(e),
                });
              }}
              handle={handle}
              style={{ marginBottom: 40 }}
            />
          </div>
          <div className="group">
            <p>Y</p>
            <Range
              min={0}
              max={6.28}
              step={0.01}
              marks={{
                0: "0",
                6.28: "2π",
              }}
              value={[rotation.y]}
              onChange={(e) => {
                setRotation({
                  ...rotation,
                  y: parseFloat(e),
                });

                updateViewer("rotation", {
                  ...rotation,
                  y: parseFloat(e),
                });
              }}
              handle={handle}
              style={{ marginBottom: 40 }}
            />
          </div>
          <div className="group">
            <p>Z</p>
            <Range
              min={0}
              max={6.28}
              step={0.01}
              marks={{
                0: "0",
                6.28: "2π",
              }}
              value={[rotation.z]}
              onChange={(e) => {
                setRotation({
                  ...rotation,
                  z: parseFloat(e),
                });

                updateViewer("rotation", {
                  ...rotation,
                  z: parseFloat(e),
                });
              }}
              handle={handle}
              style={{ marginBottom: 40 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelScreen;
