import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccounts,
  createAccount,
  deleteAccount,
  editAccountDetails,
  getAvailableRoles,
} from "../actions/AccountsActions";
import { getAllCompanies } from "../actions/CompanyActions";
import "../styles/App.css";
import ConfirmModal from "../components/shared/Modal";
import Fuse from "fuse.js";
import moment from "moment";

function Users() {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);
  const accounts = useSelector(({ Accounts }) => Accounts.accounts);
  const roles = useSelector(({ Accounts }) => Accounts.roles);
  const companies = useSelector(({ Companies }) => Companies.companies);

  const [addModal, setAddModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [createError, setCreateError] = useState();
  const [accountsList, setAccountsList] = useState(accounts);
  const [search, setSearch] = useState("");
  const [editUser, setEditUser] = useState({
    type: "",
    edit: false,
    key: "",
  });

  const companiesListing = new Fuse(companies, {
    keys: ["name", "id"],
    threshold: 0.2,
  });

  const accountsListing = new Fuse(accounts, {
    keys: ["name", "username", "company_name", "email", "phone_number"],
    threshold: 0.2,
  });

  useEffect(() => {
    dispatch(getAllAccounts(1));
    dispatch(getAllCompanies(user.id));
  }, []);

  return (
    <div className="users-panel">
      {confirmDelete ? (
        <ConfirmModal
          yes={async () => {
            await dispatch(deleteAccount(selectedUser.id));
            setConfirmDelete(false);
          }}
          no={() => setConfirmDelete(false)}
          text={"Are you sure you want to remove user " + selectedUser.user}
          icon="exclamation-triangle warn"
        />
      ) : null}
      {addModal ? (
        <div className="add-user-modal">
          <div className="modal">
            <div className="head">
              <p>Add new user</p>
            </div>

            {createError ? <p className="create-err">{createError}</p> : null}

            <div className="body">
              <input className="invisible-inp" type="text" autoComplete="on" />
              <input
                className="invisible-inp"
                type="password"
                autoComplete="on"
              />
              <p className="label">Username (required)</p>
              <input
                type="text"
                placeholder="Username here..."
                onChange={(e) =>
                  setUserDetails({ ...userDetails, username: e.target.value })
                }
                value={userDetails.username}
                autoComplete="none"
              />
              <p className="label">Password (required)</p>
              <input
                type="password"
                placeholder="Password here..."
                autoComplete="none"
                value={userDetails.password}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, password: e.target.value })
                }
              />
              <div className="company-holder">
                <p className="label">Company (required)</p>
                <input
                  type="text"
                  placeholder="Company here..."
                  onFocus={() => {
                    setOpenSuggestions(true);
                    setAvailableCompanies(
                      companies.map((company) => ({ item: company }))
                    );
                  }}
                  onBlur={() =>
                    setTimeout(() => {
                      setOpenSuggestions(false);
                    }, 500)
                  }
                  onChange={(e) => {
                    setAvailableCompanies(
                      companiesListing.search(e.target.value)
                    );
                    setSelectedCompany(e.target.value);
                  }}
                  value={selectedCompany}
                  autoComplete="none"
                />
                {openSuggestions ? (
                  <div className="auto-complete">
                    {availableCompanies.map((entry) => (
                      <li
                        onClick={async () => {
                          setSelectedCompany(entry.item.name);
                          const availableRoles = await dispatch(
                            getAvailableRoles(user.id, entry.item.id)
                          );
                          setUserDetails({
                            ...userDetails,
                            company: entry.item.id,
                            permission_id: availableRoles[0]?.Id,
                          });
                        }}
                      >
                        {entry.item.name}
                      </li>
                    ))}
                  </div>
                ) : null}
              </div>
              <p className="label">Role (required)</p>
              <select
                className={selectedCompany ? null : "disabled"}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    permission_id: e.target.value,
                  })
                }
              >
                {selectedCompany ? (
                  roles.map((entry) => (
                    <option value={entry.Id}>{entry.name}</option>
                  ))
                ) : (
                  <option>Select Company to display roles</option>
                )}
              </select>

              <p className="label">Name</p>
              <input
                type="text"
                placeholder="Name here..."
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
                value={userDetails.name}
                autoComplete="none"
              />
              <p className="label">Email</p>
              <input
                type="email"
                placeholder="Email here..."
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
                value={userDetails.email}
                autoComplete="none"
              />
              <p className="label">Phone</p>
              <input
                type="phone"
                placeholder="Phone here..."
                onChange={(e) =>
                  setUserDetails({ ...userDetails, phone: e.target.value })
                }
                value={userDetails.phone}
                autoComplete="none"
              />
            </div>
            <div className="bottom">
              <div
                className="btn"
                onClick={() => {
                  setUserDetails({});
                  setSelectedCompany();
                  setAddModal(false);
                  setCreateError("");
                }}
              >
                Cancel
              </div>
              <div
                className="btn"
                onClick={async () => {
                  try {
                    await dispatch(
                      createAccount(
                        userDetails.name,
                        userDetails.username,
                        userDetails.email,
                        userDetails.phone,
                        userDetails.company,
                        userDetails.permission_id,
                        userDetails.password
                      )
                    );
                    setAddModal(false);
                    setCreateError("");
                  } catch (error) {
                    setCreateError(
                      Object.values(error?.response?.data?.errors ?? {})[0] ??
                        "An unknown error has occurred"
                    );
                  }
                }}
              >
                Create
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="page-actions">
        <div className="filters-bar">
          <div className="search">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setAccountsList(accountsListing.search(e.target.value));
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>

        {user.account_permissions.create_account ? (
          <div className="add-btn-area">
            <button
              onClick={() => {
                setAddModal(true);
                setUserDetails({});
              }}
            >
              <i className="fas fa-plus"></i> Add New User
            </button>
          </div>
        ) : null}
      </div>

      <div className="users-list">
        <div className="head">
          <p>Username</p>
          <p>Company</p>
          <p>Role</p>
          <p>Name</p>
          <p>Email</p>
          <p>Phone Number</p>
          <p>Last Login</p>
          <p>Action</p>
        </div>
        {(search ? accountsList : accounts)
          .map((account) => (account.item ? account.item : account))
          .sort((a, b) =>
            a.company_name.toLowerCase() > b.company_name.toLowerCase()
              ? 1
              : a.company_name.toLowerCase() < b.company_name.toLowerCase()
              ? -1
              : a.username.toLowerCase() > b.username.toLowerCase()
              ? 1
              : a.username.toLowerCase() < b.username.toLowerCase()
              ? -1
              : 0
          )
          .map((entry, key) => (
            <div className="entry">
              <p>{entry.username}</p>

              <p>{entry.company_name}</p>

              <p>{entry.account_permissions.permission_name}</p>

              <>
                {editUser.type === "name" &&
                editUser.edit &&
                editUser.key === key ? (
                  <div className="editor">
                    <input
                      type="text"
                      defaultValue={entry.name}
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, name: e.target.value })
                      }
                    />
                    <i
                      className="fas fa-check"
                      onClick={async () => {
                        await dispatch(
                          editAccountDetails(entry.id, "name", userDetails.name)
                        );

                        setEditUser({
                          type: "",
                          edit: false,
                          key: "",
                        });
                      }}
                    ></i>
                    <i
                      className="fas fa-times"
                      onClick={() =>
                        setEditUser({
                          type: "",
                          edit: false,
                          key: "",
                        })
                      }
                    ></i>
                  </div>
                ) : (
                  <p>
                    {entry.name}{" "}
                    {user.account_permissions.update_account ? (
                      <i
                        className="fas fa-pen"
                        onClick={() =>
                          setEditUser({
                            type: "name",
                            edit: true,
                            key: key,
                          })
                        }
                      ></i>
                    ) : null}
                  </p>
                )}
              </>

              <>
                {editUser.type === "email" &&
                editUser.edit &&
                editUser.key === key ? (
                  <div className="editor">
                    <input
                      type="text"
                      defaultValue={entry.email}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          email: e.target.value,
                        })
                      }
                    />
                    <i
                      className="fas fa-check"
                      onClick={async () => {
                        await dispatch(
                          editAccountDetails(
                            entry.id,
                            "email",
                            userDetails.email
                          )
                        );

                        setEditUser({
                          type: "",
                          edit: false,
                          key: "",
                        });
                      }}
                    ></i>
                    <i
                      className="fas fa-times"
                      onClick={() =>
                        setEditUser({
                          type: "",
                          edit: false,
                          key: "",
                        })
                      }
                    ></i>
                  </div>
                ) : (
                  <p>
                    {entry.email}{" "}
                    {user.account_permissions.update_account ? (
                      <i
                        className="fas fa-pen"
                        onClick={() =>
                          setEditUser({
                            type: "email",
                            edit: true,
                            key: key,
                          })
                        }
                      ></i>
                    ) : null}
                  </p>
                )}
              </>

              <>
                {editUser.type === "phone" &&
                editUser.edit &&
                editUser.key === key ? (
                  <div className="editor">
                    <input
                      type="text"
                      defaultValue={entry.phone_number}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          phone: e.target.value,
                        })
                      }
                    />
                    <i
                      className="fas fa-check"
                      onClick={async () => {
                        await dispatch(
                          editAccountDetails(
                            entry.id,
                            "phone_number",
                            userDetails.phone
                          )
                        );

                        setEditUser({
                          type: "",
                          edit: false,
                          key: "",
                        });
                      }}
                    ></i>
                    <i
                      className="fas fa-times"
                      onClick={() =>
                        setEditUser({
                          type: "",
                          edit: false,
                          key: "",
                        })
                      }
                    ></i>
                  </div>
                ) : (
                  <p>
                    {entry.phone_number}{" "}
                    {user.account_permissions.update_account ? (
                      <i
                        className="fas fa-pen"
                        onClick={() =>
                          setEditUser({
                            type: "phone",
                            edit: true,
                            key: key,
                          })
                        }
                      ></i>
                    ) : null}
                  </p>
                )}
              </>

              <p>{moment(entry.last_login).format("YYYY-MM-DD hh:mm:ss A")}</p>

              {(user.id !== entry.id &&
                user.account_permissions.delete_account === "superedit") ||
              (user.id !== entry.id &&
                user.account_permissions.delete_account === "edit" &&
                entry.account_permissions.delete_account !== "superedit") ? (
                <div
                  className="remove-btn"
                  onClick={() => {
                    setSelectedUser({
                      id: entry.id,
                      user: entry.username,
                    });
                    setConfirmDelete(true);
                  }}
                >
                  REMOVE
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Users;
