import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "../styles/App.css";
import {
  getAllProducts,
  getSearchedForProducts,
  loadProducts,
  clearProducts,
  createProduct,
  updateDeploymentByID,
  fetchDeploymentPreview
} from "../actions/ProductActions";
import { getAllCompanies } from "../actions/CompanyActions";
import InfiniteScroll from "react-infinite-scroll-component";
import SingleProduct from "../components/Product/SingleProduct";
import debounce from "lodash/debounce";
import Fuse from "fuse.js";

function Products() {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);
  const companies = useSelector(({ Companies }) => Companies.companies);
  const products = useSelector(({ Products }) => Products.products);
  const prodsAvailable = useSelector(({ Products }) => Products.prodsAvailable);
  const createdProd = useSelector(({ Products }) => Products.createdProd);

  const [changeDepMod, setChangeDepMod] = useState({
    active: false,
    deploymentID: null,
    deploymentSlug: null,
    currentProd: null
  });
  const [sortBy, setSortBy] = useState("newest");
  const [searchValue, setSearchValue] = useState(params.search ?? "");
  const [page, setPage] = useState(1);
  const [copySettings, setCopySettings] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [userCopySettings, setUserCopySettings] = useState({
    product: false,
    brand: false,
    style: false,
    object: false,
    article: false
  });
  const [searchBy, setSearchBy] = useState(
    params.search ? "company_name" : "product_name"
  );
  const [prodSuggestions, setProdSuggestions] = useState(false);
  const [searchProductValue, setSearchProductValue] = useState("");
  const [searchProductSuggestions, setSearchProductSuggestions] = useState([]);
  const [selectedSuggestedProd, setSelectedSuggestedProd] = useState();
  const [newProdCreator, setNewProdCreator] = useState(false);
  const [newProdDetails, setNewProdDetails] = useState({
    brand: "",
    style: "",
    object: "",
    article: ""
  });
  const [prodDotMenu, setProdDotMenu] = useState("");

  useEffect(() => {
    const userSettings = localStorage.getItem("copySettings");

    if (params.search) {
      dispatch(
        getSearchedForProducts(user.id, sortBy, params.search, searchBy)
      );
    } else {
      dispatch(getAllProducts(user.id, sortBy));
    }

    userSettings
      ? setUserCopySettings(JSON.parse(userSettings))
      : console.log("no settings found");

    dispatch(getAllCompanies(user.id));
  }, []);

  const productsListing = new Fuse(products, {
    keys: ["product.name"],
    threshold: 0.2
  });

  const companiesListing = new Fuse(companies, {
    keys: ["name", "id"],
    threshold: 0.2
  });

  const searchProducts = debounce((value) => {
    dispatch(clearProducts());
    setPage(1);
    setSearchSuggestions(companiesListing.search(value));
    value === ""
      ? dispatch(getAllProducts(user.id, sortBy))
      : dispatch(getSearchedForProducts(user.id, sortBy, value, searchBy));
  }, 400);

  return (
    <div className="product-panel">
      {changeDepMod.active ? (
        <div className="change-dep-prod">
          <div className="mod">
            <div className="head">
              Change deployment product{" "}
              <i
                className="fas fa-times"
                onClick={() =>
                  setChangeDepMod({
                    ...changeDepMod,
                    active: false
                  })
                }
              ></i>
            </div>
            <div className="body">
              <div className="change-inputs">
                <p className="label">
                  Enter existing or new product name that you wish to move
                  deployment id: <code>{changeDepMod.deploymentSlug}</code> to
                </p>
                <div className="input-holder">
                  <input
                    type="text"
                    onChange={(e) => {
                      setSearchProductSuggestions(
                        productsListing.search(e.target.value)
                      );
                      setSearchProductValue(e.target.value);
                      setSelectedSuggestedProd();
                    }}
                    value={searchProductValue}
                    onFocus={() => setProdSuggestions(true)}
                    onBlur={() =>
                      setTimeout(() => {
                        setProdSuggestions(false);
                      }, 200)
                    }
                  />
                  <div
                    className="create-btn"
                    onClick={async () => {
                      if (selectedSuggestedProd) {
                        await dispatch(
                          updateDeploymentByID(
                            changeDepMod.deploymentID,
                            selectedSuggestedProd,
                            user.id,
                            {
                              product_id: selectedSuggestedProd
                            }
                          )
                        );
                        setChangeDepMod({
                          ...changeDepMod,
                          active: false
                        });
                        searchProducts("");
                        dispatch(
                          fetchDeploymentPreview(
                            "dev",
                            false,
                            changeDepMod.deploymentSlug,
                            user.id,
                            null
                          )
                        );
                      } else {
                        setNewProdCreator(true);
                      }
                    }}
                  >
                    Confirm
                  </div>
                  {prodSuggestions ? (
                    <div className="prod-suggestions">
                      {searchProductSuggestions.map((suggestion) => (
                        <li
                          onClick={() => {
                            setSelectedSuggestedProd(
                              suggestion.item.product.id
                            );
                            setSearchProductValue(suggestion.item.product.name);
                            setNewProdCreator(false);
                          }}
                        >
                          {suggestion.item.product.name}
                        </li>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={"create-new " + (newProdCreator ? "active" : "")}>
                <p className="create-prod">
                  Please fill these fields to create the new product and move
                  the selected deployment to it.
                </p>
                <div className="input-group">
                  <p className="label">Name</p>
                  <input
                    type="text"
                    value={searchProductValue}
                    onChange={(e) => setSearchProductValue(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <p className="label">Brand</p>
                  <input
                    type="text"
                    onChange={(e) =>
                      setNewProdDetails({
                        ...newProdDetails,
                        brand: e.target.value
                      })
                    }
                  />
                </div>
                <div className="input-group">
                  <p className="label">Style</p>
                  <input
                    type="text"
                    onChange={(e) =>
                      setNewProdDetails({
                        ...newProdDetails,
                        style: e.target.value
                      })
                    }
                  />
                </div>
                <div className="input-group">
                  <p className="label">Type</p>
                  <input
                    type="text"
                    onChange={(e) =>
                      setNewProdDetails({
                        ...newProdDetails,
                        object: e.target.value
                      })
                    }
                  />
                </div>
                <div className="input-group">
                  <p className="label">Article Number</p>
                  <input
                    type="text"
                    onChange={(e) =>
                      setNewProdDetails({
                        ...newProdDetails,
                        article: e.target.value
                      })
                    }
                  />
                </div>
                <div
                  className="create-btn"
                  onClick={async () => {
                    const newProduct = await dispatch(
                      createProduct(
                        searchProductValue,
                        newProdDetails.brand,
                        newProdDetails.style,
                        newProdDetails.object,
                        newProdDetails.article,
                        user.id
                      )
                    );

                    await dispatch(
                      updateDeploymentByID(
                        changeDepMod.deploymentID,
                        newProduct.id,
                        user.id,
                        {
                          product_id: selectedSuggestedProd
                        }
                      )
                    );

                    dispatch(
                      fetchDeploymentPreview(
                        "dev",
                        false,
                        changeDepMod.deploymentSlug,
                        user.id,
                        null
                      )
                    );

                    setChangeDepMod({
                      ...changeDepMod,
                      active: false
                    });

                    searchProducts("");
                  }}
                >
                  Create and Move
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="page-actions">
        <div className="filters-bar">
          <div className="search">
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                searchProducts(e.target.value);
                setSearchValue(e.target.value);
              }}
            />
            {(searchBy === "company_name" || searchBy === "brand_name") &&
            searchSuggestions.length ? (
              <div className="suggestions">
                {searchSuggestions.map((entry) => (
                  <li
                    onClick={() => {
                      setSearchValue(entry.item.name);
                      setSearchSuggestions([]);
                      dispatch(
                        getSearchedForProducts(
                          user.id,
                          sortBy,
                          entry.item.name,
                          searchBy
                        )
                      );
                    }}
                  >
                    {entry.item.name}
                  </li>
                ))}
              </div>
            ) : null}
            <select
              onChange={(e) => {
                setSearchValue("");
                dispatch(clearProducts());
                setPage(1);
                setSearchBy(e.target.value);
                dispatch(getAllProducts(user.id, sortBy));
                setSearchSuggestions([]);
              }}
              defaultValue={searchBy}
            >
              <option value="product_name">Product name</option>
              <option value="company_name">Company name</option>
              <option value="brand_name">Brand name</option>
              <option value="style">Style</option>
              <option value="object_type">Object type</option>
              <option value="article_number">Article no.</option>
              <option value="deployment_id">Deployment ID</option>
              <option value="comment">Comment</option>
            </select>
          </div>

          <div className="dropdown-filters">
            <p className="label">Sort By:</p>
            <select
              name="sort"
              onChange={(e) => {
                dispatch(clearProducts());
                setPage(1);
                searchValue
                  ? dispatch(
                      getSearchedForProducts(
                        user.id,
                        e.target.value,
                        searchValue,
                        searchBy
                      )
                    )
                  : dispatch(getAllProducts(user.id, e.target.value));
                setSortBy(e.target.value);
              }}
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
              <option value="alphabetical">Product Name</option>
            </select>
          </div>
        </div>

        <div className="add-btn-area">
          <Link to="/new-product">
            <button>
              <i className="fas fa-plus"></i> Add New Product
            </button>
          </Link>
        </div>
      </div>

      <div className={"copy-settings " + (copySettings ? "active" : null)}>
        <p className="title">Select tags to append to URL</p>

        <div className="input-group">
          <input
            type="checkbox"
            checked={userCopySettings.product}
            onChange={(e) =>
              setUserCopySettings({
                ...userCopySettings,
                product: e.target.checked
              })
            }
          />
          <p className="label">Product Name</p>
        </div>

        <div className="input-group">
          <input
            type="checkbox"
            checked={userCopySettings.brand}
            onChange={(e) =>
              setUserCopySettings({
                ...userCopySettings,
                brand: e.target.checked
              })
            }
          />
          <p className="label">Brand Name</p>
        </div>

        <div className="input-group">
          <input
            type="checkbox"
            checked={userCopySettings.style}
            onChange={(e) =>
              setUserCopySettings({
                ...userCopySettings,
                style: e.target.checked
              })
            }
          />
          <p className="label">Style</p>
        </div>

        <div className="input-group">
          <input
            type="checkbox"
            checked={userCopySettings.object}
            onChange={(e) =>
              setUserCopySettings({
                ...userCopySettings,
                object: e.target.checked
              })
            }
          />
          <p className="label">Object Type</p>
        </div>

        <div className="input-group">
          <input
            type="checkbox"
            checked={userCopySettings.article}
            onChange={(e) =>
              setUserCopySettings({
                ...userCopySettings,
                article: e.target.checked
              })
            }
          />
          <p className="label">Article No.</p>
        </div>

        <div
          className="save-btn"
          onClick={() => {
            localStorage.setItem(
              "copySettings",
              JSON.stringify(userCopySettings)
            );
            setCopySettings(false);
          }}
        >
          SAVE
        </div>
      </div>

      <div className="products-container" id="products-scroller">
        <InfiniteScroll
          dataLength={products?.length}
          next={() => {
            if (prodsAvailable > page) {
              dispatch(
                loadProducts(
                  user.id,
                  page + 1,
                  searchValue ? true : false,
                  sortBy,
                  searchBy,
                  searchValue
                )
              );
              setPage(page + 1);
            }
          }}
          scrollableTarget="products-scroller"
          hasMore={true}
          loader={
            prodsAvailable > page ? (
              <p className="loading-text">Loading...</p>
            ) : (
              <p className="loading-text">You viewed all available products!</p>
            )
          }
          className="infinite-scroll-container"
        >
          {products.map((product, key) => (
            <SingleProduct
              details={product}
              key={product.product.id}
              copySettings={(value) => setCopySettings(value)}
              userCopySettings={userCopySettings}
              setChangeDepMod={(value) => setChangeDepMod(value)}
              sort={sortBy}
              position={key}
              dotMenu={prodDotMenu}
              openDotMenu={(id) => setProdDotMenu(id)}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default Products;
