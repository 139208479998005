import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "../../../../../styles/Slider.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip>
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

function LightingScreen({ showOpenSetting, updateViewer, currentSettings }) {
  const [lights, setLights] = useState(currentSettings?.lighting?.lights);
  const [selectedLights, setSelectedLights] = useState([]);

  const updateLightColor = (color, index) => {
    const updatedLights = lights.map((light, key) =>
      key === index
        ? {
            ...light,
            color: { r: color.r / 255, g: color.g / 255, b: color.b / 255 },
          }
        : light
    );

    setLights(updatedLights);
  };

  const updateSpecificSetting = (setting, value, index) => {
    const updatedLights = lights.map((light, key) =>
      key === index
        ? {
            ...light,
            [setting]: value,
          }
        : light
    );

    setLights(updatedLights);
  };

  const updatePositionControls = (position, value, index) => {
    const updatedLights = lights.map((light, key) =>
      key === index
        ? {
            ...light,
            position: {
              ...light.position,
              [position]: value * 1000,
            },
          }
        : light
    );

    setLights(updatedLights);
  };

  const deleteLight = (index) => {
    const updatedLights = lights.filter((light, key) =>
      key !== index ? light : null
    );

    setLights(updatedLights);
  };

  const collapseLight = (value) => {
    const updatedSelectedLights = selectedLights.filter(
      (light) => light !== value
    );

    setSelectedLights(updatedSelectedLights);
  };

  const addLight = (index) => {
    const defaultLight = {
      type: "directional",
      color: { r: 1, g: 1, b: 1 },
      intensity: 0.8,
      position: { x: 0, y: 1, z: 0 },
      castShadow: false,
    };

    setLights([...lights, defaultLight]);
  };

  useEffect(() => {
    updateViewer("lights", lights);
  }, [lights]);

  return (
    <div
      className={
        "active-setting-panel lights-menu " + (showOpenSetting ? "show" : "")
      }
    >
      {lights.map((light, key) => (
        <div
          className={
            light.type +
            " setting-entry " +
            light.type +
            key +
            (selectedLights.includes(light.type + key) ? " active" : "")
          }
          onClick={() => {
            selectedLights.includes(light.type + key)
              ? console.log("collapsed")
              : setSelectedLights([...selectedLights, light.type + key]);
          }}
        >
          <p
            className="label"
            onClick={() => {
              selectedLights.includes(light.type + key)
                ? collapseLight(light.type + key)
                : setSelectedLights([...selectedLights, light.type + key]);
            }}
          >
            {light.type === "ambient" ? "Ambient" : "Directional"}
            {light.type === "ambient" ? null : (
              <i
                className="fas fa-times"
                title="Remove Light"
                onClick={() => deleteLight(key)}
              ></i>
            )}
          </p>
          <style>
            {`
            .${light.type + key} .chrome-picker {
              background-color: rgb(${lights[key].color.r * 255},${
              lights[key].color.g * 255
            },${lights[key].color.b * 255}) !important;
            }
          `}
          </style>
          <ChromePicker
            color={{
              r: lights[key].color.r * 255,
              g: lights[key].color.g * 255,
              b: lights[key].color.b * 255,
            }}
            disableAlpha={true}
            onChange={(color) => updateLightColor(color.rgb, key)}
            style={{ marginBottom: 40 }}
          />

          <p className="label">Intensity</p>
          <Range
            min={0}
            max={1}
            step={0.001}
            marks={{
              0: "0",
              1: "1",
            }}
            defaultValue={[lights[key].intensity]}
            onChange={(value) => updateSpecificSetting("intensity", value, key)}
            handle={handle}
            style={{ marginBottom: 40 }}
          />
          {light.type === "directional" ? (
            <>
              <p className="label">Position</p>
              <div className="xyz-inputs">
                <div className="group">
                  <p>X</p>
                  <Range
                    min={-1}
                    max={1}
                    step={0.01}
                    marks={{
                      [-1]: "-1",
                      1: "1",
                    }}
                    defaultValue={[lights[key].position.x / 1000]}
                    onChange={(value) =>
                      updatePositionControls("x", value[0], key)
                    }
                    handle={handle}
                    style={{ marginBottom: 40 }}
                  />
                </div>
                <div className="group">
                  <p>Y</p>
                  <Range
                    min={-1}
                    max={1}
                    step={0.01}
                    marks={{
                      [-1]: "-1",
                      1: "1",
                    }}
                    defaultValue={[lights[key].position.y / 1000]}
                    onChange={(value) =>
                      updatePositionControls("y", value[0], key)
                    }
                    handle={handle}
                    style={{ marginBottom: 40 }}
                  />
                </div>
                <div className="group">
                  <p>Z</p>
                  <Range
                    min={-1}
                    max={1}
                    step={0.01}
                    marks={{
                      [-1]: "-1",
                      1: "1",
                    }}
                    defaultValue={[lights[key].position.z / 1000]}
                    onChange={(value) =>
                      updatePositionControls("z", value[0], key)
                    }
                    handle={handle}
                    style={{ marginBottom: 40 }}
                  />
                </div>
              </div>

              <div className="on-off-list">
                <div className="entry">
                  <p className="label">Cast Shadow</p>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      id={"cast-shadow" + key}
                      onChange={(e) =>
                        updateSpecificSetting(
                          "castShadow",
                          e.target.checked,
                          key
                        )
                      }
                      checked={light.castShadow}
                    />
                    <label for={"cast-shadow" + key}>Toggle</label>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ))}
      <div className="setting-entry add-light" onClick={() => addLight()}>
        <p className="label">
          Add Light <i className="fas fa-plus"></i>
        </p>
      </div>

      <div className="setting-entry">
        <div className="on-off-list">
          <div className="entry">
            <p className="label">Ground Shadow</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                onChange={(e) => updateViewer("groundShadow", e.target.checked)}
                id="ground-shadow"
              />
              <label for="ground-shadow">Toggle</label>
            </div>
          </div>
          <div className="entry">
            <p className="label">Object Shadow</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                onChange={(e) => updateViewer("objectShadow", e.target.checked)}
                id="object-shadow"
              />
              <label for="object-shadow">Toggle</label>
            </div>
          </div>
          <div className="entry">
            <p className="label">Top Shadow</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                onChange={(e) => updateViewer("topShadow", e.target.checked)}
                id="top-shadow"
              />
              <label for="top-shadow">Toggle</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LightingScreen;
