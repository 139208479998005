import { ACTIONS } from "../actions/AccountsActions";

const INITIAL_STATE = {
  accounts: [],
  roles: [],
};

const accounts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.data,
      };
    case ACTIONS.SET_ROLES:
      return {
        ...state,
        roles: action.data,
      };
    default:
      return state;
  }
};

export default accounts;
