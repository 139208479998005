import { ACTIONS } from "../actions/CompanyActions";

const INITIAL_STATE = {
  companies: [],
};

const companies = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_COMPANIES:
      return {
        ...state,
        companies: action.data,
      };
    default:
      return state;
  }
};

export default companies;
