import Http from "axios";
import { ACTIONS } from "../actions/UserActions";

const accessToken = localStorage.getItem("accessToken");
const user = localStorage.getItem("user");

const safeParseFloat = (number) => {
  const parsedNumber = parseFloat(number, 10);

  if (!parsedNumber) {
    return 0;
  }

  return parsedNumber;
};

if (accessToken) {
  Http.defaults.headers.common[
    "X-Baetes-Access-Token"
  ] = `Bearer ${accessToken}`;
}

const INITIAL_STATE = {
  user: user ? JSON.parse(user) : null,
  accessToken,
  accessTokenExpiration: safeParseFloat(
    localStorage.getItem("accessTokenExpiration")
  ),
};

const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      if (action.data) {
        localStorage.setItem("user", JSON.stringify(action.data));
      }
      return {
        ...state,
        user: action.data,
      };
    case ACTIONS.SET_ACCESS_TOKEN:
      if (action.data) {
        Http.defaults.headers.common[
          "X-Baetes-Access-Token"
        ] = `Bearer ${action.data}`;
        localStorage.setItem("accessToken", action.data);
      } else {
        localStorage.removeItem("accessToken");
      }
      return {
        ...state,
        accessToken: action.data,
      };
    case ACTIONS.SET_ACCESS_TOKEN_EXPIRATION:
      const parsedExpiration = safeParseFloat(action.data);
      if (parsedExpiration) {
        localStorage.setItem("accessTokenExpiration", action.data);
      } else {
        localStorage.removeItem("accessTokenExpiration");
      }
      return {
        ...state,
        accessTokenExpiration: parsedExpiration ? parsedExpiration : null,
      };
    case ACTIONS.SET_REFRESH_TOKEN:
      if (action.data) {
        localStorage.setItem("refreshToken", action.data);
      } else {
        localStorage.removeItem("refreshToken");
      }
      return {
        ...state,
        refreshToken: action.data,
      };
    default:
      return state;
  }
};

export default account;
