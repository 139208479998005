import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { resetAccount } from "../../actions/UserActions";
import "../../styles/App.css";
import logo from "../../images/baetes_white.png";

function TopNav() {
  const dispatch = useDispatch();

  return (
    <div className="topnav">
      <div className="logo-holder">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="links-holder">
        <NavLink to="/products" activeClassName="active" title="Products">
          <i className="fas fa-cube"></i>
        </NavLink>
        <NavLink to="/companies" activeClassName="active" title="Companies">
          <i className="fas fa-building"></i>
        </NavLink>
        <NavLink to="/tools" activeClassName="active" title="Tools">
          <i className="fas fa-toolbox"></i>
        </NavLink>
        <NavLink to="/users" activeClassName="active" title="Users">
          <i className="fas fa-users"></i>
        </NavLink>
        <NavLink to="/profile" activeClassName="active" title="Profile">
          <i className="fas fa-user"></i>
        </NavLink>
        <a href="/" onClick={() => dispatch(resetAccount())} title="Logout">
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </div>
    </div>
  );
}

export default TopNav;
