import { ACTIONS } from "../actions/ProductActions";

const INITIAL_STATE = {
  products: [],
  deployments: [],
  prodsAvailable: 0,
  createdProd: {},
};

const products = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_PRODUCTS:
      return {
        ...state,
        products: action.data.products,
        prodsAvailable: Math.ceil(
          action.data.product_count / action.data.pagination
        ),
      };
    case ACTIONS.LOAD_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...action.data.products],
      };
    case ACTIONS.SET_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.data,
      };
    case ACTIONS.CREATED_PRODUCT:
      return {
        ...state,
        createdProd: action.data,
      };
    default:
      return state;
  }
};

export default products;
