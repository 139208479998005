import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import "../styles/App.css";
import SettingsPanelV114 from "../components/singleProduct/settings/version-1.1.4";
import useResizeObserver from "../components/Other/useResizeObserver";

const timestamp = Date.now();

function SingleProduct() {
  const [currentSettings, setCurrentSettings] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [ratio, setRatio] = useState();

  const params = useParams();
  const deploymentID = params.id.split("/")[0];

  const getCurrentSettings = () => {
    document.querySelector(".viewer-iframe").contentWindow.postMessage(
      JSON.stringify({
        type: "getSettings",
      }),
      "*"
    );
  };

  const getCurrentPosition = () =>
    new Promise((resolve, reject) => {
      document.querySelector(".viewer-iframe").contentWindow.postMessage(
        JSON.stringify({
          type: "getCameraPos",
        }),
        "*"
      );

      const eventListener = (e) => {
        if (
          e.origin === "https://testproduct.baetes.com" ||
          e.origin === "https://product.baetes.com"
        ) {
          if (
            JSON.parse(e.data).type === "cameraPos" &&
            currentSettings?.modelFile
          ) {
            const parsedEvent = JSON.parse(e.data);
            setCurrentSettings({
              ...currentSettings,
              camera: {
                ...(currentSettings?.camera ?? {}),
                defaultCam: {
                  x: parsedEvent.data.x,
                  y: parsedEvent.data.y,
                  z: parsedEvent.data.z,
                },
              },
            });
            window.removeEventListener("message", eventListener);
            resolve(parsedEvent.data);
          }
        }
      };

      window.addEventListener("message", eventListener);
    });

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (
        e.origin === "https://testproduct.baetes.com" ||
        e.origin === "https://product.baetes.com"
      ) {
        if (e.data === '"loaded"') {
          getCurrentSettings();
        } else {
          if (!JSON.parse(e.data).type) {
            setCurrentSettings(JSON.parse(e.data));
            setSettingsOpen(true);
          }
        }
      }
    });
  }, []);

  const [viewerIframe, { contentRect }] = useResizeObserver();

  const getContentRect = useCallback(
    (key) => contentRect && Math.round(contentRect[key]),
    [contentRect]
  );

  return (
    <div
      className={
        "single-product-panel " + (settingsOpen ? "settings-open" : null)
      }
    >
      <div className="top-btns-nav">
        <div className="double-btn">
          <Link to="/products">
            <div className="settings-open-btn">
              <i className="fas fa-chevron-left"></i> Back to Products
            </div>
          </Link>
        </div>

        <select onChange={(e) => setRatio(e.target.value)}>
          <option value={0}>Fill</option>
          <option value={0.5625}>16:9</option>
          <option value={0.666}>3:2</option>
          <option value={1}>1:1</option>
          <option value={1.666}>2:3</option>
          <option value={1.5625}>9:16</option>
        </select>
      </div>
      <div
        className={
          "baetes-viewer-holder " + (settingsOpen ? "settings-open" : null)
        }
        ref={viewerIframe}
      >
        {/* Viewer iframe */}
        <iframe
          src={`https://testproduct.baetes.com/VV0DR8AQ/?viewerVersion=dev_1.1.4&ver=${timestamp}`}
          // src={`https://testproduct.baetes.com/${deploymentID}/?ver=${timestamp}`}
          frameborder="0"
          title={deploymentID}
          className="viewer-iframe"
          style={
            ratio === "0"
              ? { height: "100%" }
              : ratio === "1"
              ? {
                  height:
                    getContentRect("width") > getContentRect("height")
                      ? getContentRect("height")
                      : getContentRect("width"),
                  width:
                    getContentRect("width") > getContentRect("height")
                      ? getContentRect("height")
                      : getContentRect("width"),
                }
              : parseFloat(ratio) < 1
              ? {
                  height:
                    getContentRect("width") * ratio > getContentRect("height")
                      ? "100%"
                      : getContentRect("width") * ratio,
                  width:
                    getContentRect("width") * ratio > getContentRect("height")
                      ? getContentRect("width") -
                        (getContentRect("width") * ratio -
                          getContentRect("height"))
                      : "100%",
                }
              : parseFloat(ratio) > 1
              ? {
                  height: "100%",
                  width: getContentRect("height") * (ratio - 1),
                }
              : {}
          }
        ></iframe>
      </div>
      <SettingsPanelV114
        settingsOpen={currentSettings ? settingsOpen : false}
        setSettingsOpen={() => setSettingsOpen(false)}
        toggleSettings={() => setSettingsOpen(!settingsOpen)}
        currentSettings={currentSettings}
        getCurrentSettings={() => getCurrentSettings()}
        getCurrentPosition={() => getCurrentPosition()}
      />
    </div>
  );
}

export default SingleProduct;
