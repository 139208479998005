import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmModal from "../components/shared/Modal";
import "../styles/App.css";
import {
  getAllCompanies,
  getCompanyLogo,
  createCompany,
  deleteCompany,
  editCompanyDetails,
} from "../actions/CompanyActions";
import placeholder from "../images/logo-placeholder.png";
import ReactImageFallback from "react-image-fallback";
import logo from "../images/baetes_white.png";

import moment from "moment";

function Companies() {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);
  const companies = useSelector(({ Companies }) => Companies.companies);

  const [addModal, setAddModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [companyURL, setCompanyURL] = useState();
  const [deleteID, setDeleteID] = useState();
  const [nameEditor, setNameEditor] = useState({ open: false, id: null });
  const [linkEditor, setLinkEditor] = useState({ open: false, id: null });
  const [sort, setSort] = useState("name");
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getAllCompanies(user.id));
  }, []);

  return (
    <div className="companies-panel">
      {/* Confirm modal */}
      {confirmModal ? (
        <ConfirmModal
          yes={async () => {
            await dispatch(deleteCompany(user.id, deleteID));
            setConfirmModal(false);
          }}
          no={() => setConfirmModal(false)}
          text="Are you sure you want to confirm changes to this field and save?"
          icon="exclamation-triangle warn"
        />
      ) : null}
      {/* Add company modal */}
      {addModal ? (
        <div className="add-company-modal">
          <div className="modal">
            <div className="head">
              <p>Add new company</p>
            </div>
            <div className="body">
              <p className="label">Company Name</p>
              <input
                type="text"
                placeholder="Company Name here..."
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <p className="label">Company Website</p>
              <input
                type="text"
                placeholder="Company URL here..."
                onChange={(e) =>
                  setCompanyURL(e.target.value.split("//").slice(-1)[0])
                }
              />
            </div>
            <div className="bottom">
              <div className="btn" onClick={() => setAddModal(false)}>
                Cancel
              </div>
              <div
                className="btn"
                onClick={async () => {
                  await dispatch(
                    createCompany(user.id, companyName, companyURL)
                  );
                  setAddModal(false);
                }}
              >
                Create
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="page-actions">
        <div className="filters-bar">
          <div className="search">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="dropdown-filters">
            <p className="label">Sort By:</p>
            <select name="sort" onChange={(e) => setSort(e.target.value)}>
              <option value="name">Name</option>
              <option value="deployments">Deployments</option>
              <option value="users">Users</option>
              <option value="date">Date Added</option>
            </select>
          </div>
        </div>

        <div className="add-btn-area">
          <button onClick={() => setAddModal(true)}>
            <i className="fas fa-plus"></i> Add New Company
          </button>
        </div>
      </div>

      <div className="companies-list">
        {companies
          .sort((a, b) =>
            sort === "name"
              ? a.name > b.name
                ? 1
                : a.name < b.name
                ? -1
                : 0
              : sort === "deployments"
              ? a.deployments > b.deployments
                ? -1
                : a.deployments < b.deployments
                ? 1
                : 0
              : sort === "users"
              ? a.accounts > b.accounts
                ? -1
                : a.accounts < b.accounts
                ? 1
                : 0
              : sort === "date"
              ? moment.utc(a.created_at).isAfter(moment.utc(b.created_at))
                ? -1
                : moment.utc(a.created_at).isBefore(moment.utc(b.created_at))
                ? 1
                : 0
              : null
          )
          .filter(
            (entry) =>
              entry.name.toUpperCase().indexOf(search.toUpperCase()) > -1
          )
          .map((entry) => (
            <div className="company-entry">
              {entry.deployments === 0 && entry.accounts === 0 ? (
                <div
                  className="delete-btn"
                  onClick={() => {
                    setDeleteID(entry.id);
                    setConfirmModal(true);
                  }}
                >
                  <i className="fas fa-times"></i>
                </div>
              ) : null}
              <div className="logo-holder-div">
                <ReactImageFallback
                  src={
                    entry.id === 0
                      ? logo
                      : `https://testportal.baetes.com/static/logos/${entry.id}.png`
                  }
                  fallbackImage={placeholder}
                  alt=""
                  className="logo-area"
                />
              </div>

              <div className="details">
                {nameEditor.open && nameEditor.id === entry.id ? (
                  <div className="editor">
                    <input
                      type="text"
                      defaultValue={entry.name}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <i
                      className="fas fa-times"
                      onClick={() => setNameEditor({ open: false, id: null })}
                    ></i>
                    <i
                      className="fas fa-check"
                      onClick={() => {
                        dispatch(
                          editCompanyDetails(
                            user.id,
                            entry.id,
                            "name",
                            companyName
                          )
                        );
                        setNameEditor({ open: false, id: null });
                      }}
                    ></i>
                  </div>
                ) : (
                  <div className="name-holder">
                    <p className="name">{entry.name}</p>
                    <i
                      className="fas fa-pen"
                      onClick={() =>
                        setNameEditor({ open: true, id: entry.id })
                      }
                    ></i>
                  </div>
                )}
                {linkEditor.open && linkEditor.id === entry.id ? (
                  <div className="editor">
                    <input
                      type="text"
                      defaultValue={entry.url}
                      onChange={(e) =>
                        setCompanyURL(e.target.value.split("//").slice(-1)[0])
                      }
                    />
                    <i
                      className="fas fa-times"
                      onClick={() => setLinkEditor({ open: false, id: null })}
                    ></i>
                    <i
                      className="fas fa-check"
                      onClick={async () => {
                        dispatch(
                          editCompanyDetails(
                            user.id,
                            entry.id,
                            "url",
                            companyURL
                          )
                        );
                        setLinkEditor({ open: false, id: null });
                        if (companyURL) {
                          dispatch(
                            getCompanyLogo(user.id, entry.id, companyURL)
                          );
                        }
                      }}
                    ></i>
                  </div>
                ) : (
                  <div className="link-holder">
                    <p className="link">
                      {entry.url ? (
                        <a
                          href={"https://" + entry.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-link"></i> {entry.url}
                        </a>
                      ) : (
                        <span>
                          <i className="fas fa-link"></i> No link available
                        </span>
                      )}
                    </p>
                    <i
                      className="fas fa-pen"
                      onClick={() =>
                        setLinkEditor({ open: true, id: entry.id })
                      }
                    ></i>
                  </div>
                )}
                <Link to={"/products/" + entry.name}>
                  <p className="info">
                    <b>Deployments</b>
                    <br />
                    {entry.deployments}
                  </p>
                </Link>
                <p className="info">
                  <b>Users</b>
                  <br />
                  {entry.accounts}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Companies;
