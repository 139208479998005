import React, { useState, useCallback } from "react";
import "../../styles/App.css";
import { useDropzone } from "react-dropzone";
import { ChromePicker } from "react-color";

function Tile({
  deleteTile,
  name,
  textureType,
  nameEdit,
  updateAlbedo,
  updateAlbedoName,
  updateTexture,
  updateTextureName,
  type,
  albedoType,
  updateAlbedoType,
  currentColor
}) {
  const [previewImage, setPreviewImage] = useState();
  const [editorMode, setEditorMode] = useState(false);
  const [nameInput, setNameInput] = useState(name);
  const [fileName, setFileName] = useState("No image");

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
        setPreviewImage(binaryStr);
        setFileName(file.name);

        type === "Albedo"
          ? updateAlbedo(file.name, binaryStr, null)
          : updateTexture(file.name, binaryStr, null);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ["image/jpg", "image/png", "image/jpeg"],
    maxSize: 25000000 // in bytes
  });

  return (
    <div className="card">
      <i className="fas fa-times delete-card" onClick={() => deleteTile()}></i>
      <div className="label">
        {editorMode ? (
          <div className="editor">
            <input
              type="text"
              value={nameInput ?? name}
              onChange={(e) =>
                e.target.value.replace(/[a-zA-Z0-9\s]+/g, "") === ""
                  ? setNameInput(e.target.value)
                  : null
              }
            />
            <i
              className="fas fa-times"
              onClick={() => setEditorMode(false)}
            ></i>
            <i
              className="fas fa-check"
              onClick={() => {
                type === "Albedo"
                  ? updateAlbedoName(nameInput)
                  : updateTextureName(nameInput);
                setEditorMode(false);
              }}
            ></i>
          </div>
        ) : (
          <p>
            {name}{" "}
            <i className="fas fa-pen" onClick={() => setEditorMode(true)}></i>
          </p>
        )}
      </div>
      {type === "Albedo" && !albedoType ? (
        <div className="albedo-selector">
          <div className="select-btn" onClick={() => updateAlbedoType("image")}>
            Image
          </div>
          <div className="select-btn" onClick={() => updateAlbedoType("color")}>
            Value
          </div>
        </div>
      ) : type === "Albedo" && albedoType === "color" ? (
        <ChromePicker
          onChange={(color) => updateAlbedo(null, null, color.hex)}
          color={currentColor}
          disableAlpha={true}
          width={"100%"}
        />
      ) : (
        <div className="preview-holder">
          <div className="checker-bg"></div>
          <div
            {...getRootProps({
              className: "preview",
              style: { backgroundImage: `url(${previewImage})` }
            })}
          >
            <input {...getInputProps()} />
            {nameEdit ? null : (
              <div className="label">
                <p>Type: {textureType}</p>
              </div>
            )}
            {previewImage ? null : (
              <div className="drop-text">
                <p>Drop Image Here</p>
              </div>
            )}
          </div>
        </div>
      )}
      {(type === "Albedo" && !albedoType) ||
      (type === "Albedo" && albedoType === "color") ? null : (
        <p className="filename">{fileName}</p>
      )}
    </div>
  );
}

export default Tile;
