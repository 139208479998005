import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import URL from "url-parse";
import "../styles/App.css";
import fontList from "../fontsList.json";
import Fuse from "fuse.js";

function ProductHotspots() {
  const params = useParams();

  const [mobileViewer, setMobileViewer] = useState(false);
  const [hotspots, setHotspots] = useState([]);
  const [openHotspots, setOpenHotspots] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [nameEditMode, setNameEditMode] = useState();
  const [availableFonts, setAvailableFonts] = useState([]);
  const [selectedFont, setSelectedFont] = useState();
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [copy, setCopy] = useState("Copy deployment link");
  const [hotspotLinkConfig, setHotspotLinkConfig] = useState(
    params.id ? `https://testproduct.baetes.com/${params.id}` : ""
  );
  const [hotspotLink, setHotspotLink] = useState(
    params.id ? `https://testproduct.baetes.com/${params.id}` : ""
  );

  const newHotspot = () => {
    setHotspots([
      ...hotspots,
      {
        id: hotspots.length + 1,
        thumbnail: undefined,
        thumbnailText: undefined,
        font: undefined,
        action: [
          {
            type: "movecamera",
            data: { x: 100, y: 100, z: 100 },
          },
        ],
        hotspotText: "",
        hotspotPosition: "top-left",
      },
    ]);
  };

  const updateHotspots = () => {
    document.querySelector(".viewer-iframe").contentWindow.postMessage(
      JSON.stringify({
        type: "configHotspots",
        data: hotspots,
      }),
      "*"
    );
  };

  const deleteHotspot = (index) => {
    const updatedHotspots = hotspots.filter((light, key) =>
      key !== index ? light : null
    );

    setHotspots(updatedHotspots);
  };

  const collapseHotspot = (value) => {
    const updatedOpenHotspots = openHotspots.filter(
      (hotspot) => hotspot !== value
    );

    setOpenHotspots(updatedOpenHotspots);
  };

  const hideEditMode = (id) => {
    const updatedOpenEditors = editMode.filter((entry) => entry !== id);

    setEditMode(updatedOpenEditors);
  };

  const updateDetails = (key, field, value) => {
    setHotspots(
      hotspots.map((entry, index) =>
        field === "thumbnail" && key === index
          ? {
              ...entry,
              thumbnailText: undefined,
              [field]: value,
            }
          : field === "thumbnailText" && key === index
          ? {
              ...entry,
              thumbnail: undefined,
              [field]: value,
            }
          : key === index
          ? {
              ...entry,
              [field]: value,
            }
          : entry
      )
    );
  };

  const getCurrentPosition = (key) =>
    new Promise((resolve, reject) => {
      document.querySelector(".viewer-iframe").contentWindow.postMessage(
        JSON.stringify({
          type: "getCameraPos",
        }),
        "*"
      );

      const eventListener = (e) => {
        if (
          e.origin === "https://testproduct.baetes.com" ||
          e.origin === "https://product.baetes.com"
        ) {
          const parsedEvent = JSON.parse(e.data);

          setHotspots(
            hotspots.map((entry, index) =>
              key === index
                ? {
                    ...entry,
                    action: [
                      {
                        type: "movecamera",
                        data: {
                          ...entry.action[0].data,
                          x: parsedEvent.data.x?.toFixed(1),
                          y: parsedEvent.data.y?.toFixed(1),
                          z: parsedEvent.data.z?.toFixed(1),
                        },
                      },
                    ],
                  }
                : entry
            )
          );

          window.removeEventListener("message", eventListener);
          resolve(parsedEvent.data);
        }
      };

      window.addEventListener("message", eventListener);
    });

  const loadConfig = (link) => {
    const url = new URL(link, true);
    const config = JSON.parse(url.query.configHotspots ?? "[]");
    const font = url.query.font;

    url.set("query", {
      ...url.query,
      configHotspots: JSON.stringify(config),
      ...(font ? { font: font } : {}),
    });

    setSelectedFont(font);
    setHotspots(config);
    setHotspotLink(
      /^htt(p|ps):\/\//gi.test(link)
        ? hotspotLinkConfig
        : "https://" + hotspotLinkConfig
    );

    setHotspotLinkConfig(
      /^htt(p|ps):\/\//gi.test(link)
        ? hotspotLinkConfig
        : "https://" + hotspotLinkConfig
    );
  };

  const generateLink = () => {
    const url = new URL(
      hotspotLinkConfig ? hotspotLinkConfig : "https://testproduct.baetes.com/",
      true
    );

    url.set("query", {
      ...url.query,
      configHotspots: JSON.stringify(hotspots),
      ...(selectedFont ? { font: selectedFont } : {}),
    });

    setHotspotLink(decodeURIComponent(url.toString()));
    setHotspotLinkConfig(decodeURIComponent(url.toString()));
  };

  const fuse = new Fuse(fontList, {
    keys: ["family"],
  });

  useEffect(() => {
    updateHotspots();
  }, [hotspots]);

  return (
    <div className="hotspots-config-panel">
      <div className="viewer">
        <div className="url-config-nav">
          <div className="btn" onClick={() => loadConfig(hotspotLinkConfig)}>
            Load Configuration
          </div>
          <div className="btn" onClick={() => generateLink()}>
            Generate New URL
          </div>
          <input
            type="text"
            placeholder="URL here"
            value={hotspotLinkConfig}
            onChange={(e) => setHotspotLinkConfig(e.target.value)}
          />
          <CopyToClipboard text={hotspotLink} onCopy={() => setCopy("Copied!")}>
            <i className="fas fa-copy" title={copy}></i>
          </CopyToClipboard>
          <i
            className="fas fa-desktop"
            onClick={() => setMobileViewer(false)}
          ></i>
          <i
            className="fas fa-mobile"
            onClick={() => setMobileViewer(true)}
          ></i>
        </div>
        {!hotspotLink ? (
          <div className="no-viewer">
            <div className="message">
              <p>
                Enter 3D viewer URL into the text field and press <br />
                'Load Configuration' to load 3D viewer
              </p>
            </div>
          </div>
        ) : null}
        <iframe
          src={hotspotLink}
          frameborder="0"
          className={"viewer-iframe " + (mobileViewer ? "mobile" : "")}
          title="viewer"
        ></iframe>
      </div>

      <div className="side-panel">
        <div className="head">
          <p>Hotspots Settings</p>
        </div>

        <div className="menu">
          {hotspots.map((entry, key) => (
            <div
              className={
                "hotspot " + (openHotspots.includes(entry.id) ? "active" : "")
              }
            >
              <div className="label">
                <div className="left-side">
                  <div
                    className="dropdown-icon"
                    onClick={() =>
                      openHotspots.includes(entry.id)
                        ? collapseHotspot(entry.id)
                        : setOpenHotspots([...openHotspots, entry.id])
                    }
                  >
                    <i className="fas fa-caret-right"></i>
                  </div>

                  <div className="name-holder">
                    {editMode.includes(entry.id) ? (
                      <div className="name-editor">
                        <input
                          type="text"
                          defaultValue={entry.title}
                          onChange={(e) => setNameEditMode(e.target.value)}
                        />
                        <i
                          className="fas fa-times"
                          onClick={() => {
                            hideEditMode(entry.id);
                            setNameEditMode("");
                          }}
                        ></i>
                        <i
                          className="fas fa-check"
                          onClick={() => {
                            updateDetails(key, "title", nameEditMode);
                            hideEditMode(entry.id);
                          }}
                        ></i>
                      </div>
                    ) : (
                      <div className="name">
                        <p>
                          {entry.title ? entry.title : "Hotspot " + (key + 1)}
                        </p>
                        <i
                          className="fas fa-pen"
                          onClick={() => {
                            setEditMode([...editMode, entry.id]);
                            setNameEditMode("");
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
                <i
                  className="fas fa-times delete-btn"
                  onClick={() => deleteHotspot(key)}
                ></i>
              </div>
              <div className="tools">
                <div className="group">
                  <p className="label">Hotspot Button Text:</p>
                  <input
                    type="text"
                    onChange={(e) =>
                      updateDetails(
                        key,
                        /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))?/.test(
                          e.target.value
                        )
                          ? "thumbnail"
                          : "thumbnailText",
                        e.target.value
                      )
                    }
                    defaultValue={entry.thumbnailText}
                    placeholder="Image URL or text"
                  />
                </div>
                <div className="group">
                  <p className="label">Hotspot Description Text:</p>
                  <textarea
                    onChange={(e) =>
                      updateDetails(key, "hotspotText", e.target.value)
                    }
                    defaultValue={entry.hotspotText}
                    placeholder="Description text here"
                  ></textarea>
                </div>
                <div className="group">
                  <p className="label">Hotspot Description Position:</p>
                  <select
                    onChange={(e) =>
                      updateDetails(key, "hotspotPosition", e.target.value)
                    }
                    defaultValue={entry.hotspotPosition}
                  >
                    <option value="top-left">Top Left</option>
                    <option value="top-center">Top Center</option>
                    <option value="top-right">Top Right</option>
                    <option value="center-left">Center Left</option>
                    <option value="center-center">Center Center</option>
                    <option value="center-right">Center Right</option>
                    <option value="bottom-left">Bottom Left</option>
                    <option value="bottom-center">Bottom Center</option>
                    <option value="bottom-right">Bottom Right</option>
                  </select>
                </div>
                <div className="group">
                  <p className="label">Camera Position:</p>
                  <div className="position-input">
                    <div className="input-holder">
                      <p>X</p>
                      <input
                        type="text"
                        value={entry.action[0].data.x}
                        onChange={(e) =>
                          setHotspots(
                            hotspots.map((entry, index) =>
                              key === index
                                ? {
                                    ...entry,
                                    action: [
                                      {
                                        type: "movecamera",
                                        data: {
                                          ...entry.action[0].data,
                                          x: parseFloat(e.target.value),
                                        },
                                      },
                                    ],
                                  }
                                : entry
                            )
                          )
                        }
                      />
                    </div>
                    <div className="input-holder">
                      <p>Y</p>
                      <input
                        type="text"
                        value={entry.action[0].data.y}
                        onChange={(e) =>
                          setHotspots(
                            hotspots.map((entry, index) =>
                              key === index
                                ? {
                                    ...entry,
                                    action: [
                                      {
                                        type: "movecamera",
                                        data: {
                                          ...entry.action[0].data,
                                          y: parseFloat(e.target.value),
                                        },
                                      },
                                    ],
                                  }
                                : entry
                            )
                          )
                        }
                      />
                    </div>
                    <div className="input-holder">
                      <p>Z</p>
                      <input
                        type="text"
                        value={entry.action[0].data.z}
                        onChange={(e) =>
                          setHotspots(
                            hotspots.map((entry, index) =>
                              key === index
                                ? {
                                    ...entry,
                                    action: [
                                      {
                                        type: "movecamera",
                                        data: {
                                          ...entry.action[0].data,
                                          z: parseFloat(e.target.value),
                                        },
                                      },
                                    ],
                                  }
                                : entry
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="current-cord-btn"
                    onClick={() => getCurrentPosition(key)}
                  >
                    <p>Get Current Coordinates</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="hotspot add-btn" onClick={() => newHotspot()}>
            <div className="label">
              <p>Add Hotspot</p>
              <i className="fas fa-plus"></i>
            </div>
          </div>

          <div className="group">
            <p className="label">Font:</p>
            <input
              type="text"
              onChange={(e) => {
                setAvailableFonts(fuse.search(e.target.value));
                setSelectedFont(e.target.value);
              }}
              value={selectedFont}
              placeholder="Enter Font name"
              onFocus={() => setOpenSuggestions(true)}
              onBlur={() =>
                setTimeout(() => {
                  setOpenSuggestions(false);
                }, 500)
              }
              className="font-inp"
            />
            {openSuggestions ? (
              <div className="suggestion-dropdown">
                {availableFonts.map((font) => (
                  <li
                    onClick={() => {
                      setSelectedFont(font.item.family);
                      setAvailableFonts([]);
                    }}
                  >
                    {font.item.family}
                  </li>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductHotspots;
