import Http from "axios";

export const ACTIONS = {
  SET_COMPANIES: "SET_COMPANIES",
};

export const getAllCompanies = (id) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/${id}/companies`,
      {
        withCredentials: false,
      }
    );
    dispatch({
      data: data,
      type: ACTIONS.SET_COMPANIES,
    });
  } catch (err) {
    throw err;
  }
};

export const getCompanyLogo = (id, companyID, companyURL) => async (
  dispatch
) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/companies/${companyID}/logo`,
      {
        company_id: companyID,
        company_url: companyURL,
      },
      {
        withCredentials: false,
      }
    );
  } catch (err) {
    throw err;
  }
};

export const createCompany = (id, name, url) => async (dispatch) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/companies/create`,
      {
        name,
        url,
      },
      {
        withCredentials: false,
      }
    );

    getAllCompanies(id)(dispatch);
  } catch (err) {
    throw err;
  }
};

export const deleteCompany = (id, companyID) => async (dispatch) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/companies/delete`,
      {
        company_id: companyID.toString(),
      },
      {
        withCredentials: false,
      }
    );

    getAllCompanies(id)(dispatch);
  } catch (err) {
    throw err;
  }
};

export const editCompanyDetails = (id, companyID, type, value) => async (
  dispatch
) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/companies/update`,
      {
        company_id: companyID.toString(),
        type,
        value,
      },
      {
        withCredentials: false,
      }
    );

    getAllCompanies(id)(dispatch);
  } catch (err) {
    throw err;
  }
};
