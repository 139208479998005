import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDeploymentCompany,
  updateDeploymentByID,
  downloadDeployment,
  fetchDeploymentPreview,
} from "../../actions/ProductActions";
import { getAllCompanies } from "../../actions/CompanyActions";
import "../../styles/App.css";
import AnimateHeight from "react-animate-height";
import ConfirmModal from "../shared/Modal";
import DangerModal from "../shared/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import Fuse from "fuse.js";
import placeholder from "../../images/logo-placeholder.png";
import ReactImageFallback from "react-image-fallback";

function CustomerEntry({
  data,
  productName,
  copySettings,
  userCopySettings,
  brandName,
  styleName,
  objectName,
  articleName,
  company,
  productID,
  setChangeDepMod,
  getCurrentProdDeps,
  clearDeps,
}) {
  const [openComment, setOpenComment] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [actionList, setActionList] = useState(false);
  const [height, setHeight] = useState(0);
  const [viewerInitHeight, setviewerInitHeight] = useState(0);
  const [viewerSize, setviewerSize] = useState("small");
  const [commentEditor, setCommentEditor] = useState(true);
  const [comment, setComment] = useState(data.comment);
  const [companyName, setCompanyName] = useState(company);
  const [companyEditor, setCompanyEditor] = useState(false);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompanyID, setSelectedCompanyID] = useState();
  const [currentCompanyText, setCurrentCompanyText] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDangerModal, setOpenDangerModal] = useState(false);
  const [allowedParams, setAllowedParams] = useState([
    userCopySettings.product && productName ? `name=${productName}` : null,
    userCopySettings.brand && brandName ? `brand=${brandName}` : null,
    userCopySettings.style && styleName ? `style=${styleName}` : null,
    userCopySettings.object && objectName ? `object=${objectName}` : null,
    userCopySettings.article && articleName ? `article=${articleName}` : null,
  ]);
  const [commentCount, setCommentCount] = useState(data.comment);
  const [copy, setCopy] = useState("Copy deployment link");
  const [detailsToBeChanged, setDetailsToBeChanged] = useState({
    refName: null,
    setTextState: null,
    inputToClose: null,
  });
  const [showLoading, setShowLoading] = useState(false);

  const commentInp = React.createRef();
  const companyInp = React.createRef();
  const params = useParams();
  const deploymentID = params.id?.split("/")[0];
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);
  const companies = useSelector(({ Companies }) => Companies.companies);

  useEffect(() => {
    dispatch(getAllCompanies(user.id));
  }, []);

  const companiesListing = new Fuse(companies, {
    keys: ["name"],
    threshold: 0.2,
  });

  const acceptInputEdits = () => {
    const { refName, setTextState, inputToClose, type } = detailsToBeChanged;
    setTextState(refName.value);
    inputToClose(false);
    setOpenConfirmModal(false);
    if (type === "company") {
      dispatch(
        updateDeploymentCompany(data.id, productID, user.id, selectedCompanyID)
      );
    } else if (type === "comment") {
      dispatch(
        updateDeploymentByID(data.id, productID, user.id, {
          comment: refName.value,
        })
      );
    }
  };

  const copySuccess = () => {
    setCopy("Copied!");
    setTimeout(() => {
      setCopy("Copy deployment link");
    }, 2000);
  };

  const downloadDep = async () => {
    setShowLoading(true);
    const cbData = await dispatch(
      downloadDeployment(productID, data.id, data.slug, user.id)
    );
    setShowLoading(false);
    window.location.href = JSON.parse(cbData.result).downloadURL;
  };

  useEffect(() => {
    setAllowedParams([
      userCopySettings.product && productName ? `name=${productName}` : null,
      userCopySettings.brand && brandName ? `brand=${brandName}` : null,
      userCopySettings.style && styleName ? `style=${styleName}` : null,
      userCopySettings.object && objectName ? `object=${objectName}` : null,
      userCopySettings.article && articleName ? `article=${articleName}` : null,
    ]);
  }, [userCopySettings]);

  return (
    <div className="customer-entry">
      {showLoading ? (
        <div className="download-loading">
          <div class="lds-hourglass"></div>
        </div>
      ) : null}

      {openDangerModal ? (
        <DangerModal
          yes={() => setOpenDangerModal(false)}
          no={() => setOpenDangerModal(false)}
          text="Are you sure you want to delete this deployment? This action can't be undone after confirming."
          icon="exclamation-triangle danger"
        />
      ) : null}

      {openConfirmModal ? (
        <ConfirmModal
          yes={() => acceptInputEdits()}
          no={() => setOpenConfirmModal(false)}
          text="Are you sure you want to confirm changes to this field and save?"
          icon="exclamation-triangle warn"
        />
      ) : null}

      <div className={"copy-notify " + (copy === "Copied!" ? "active" : "")}>
        <p>Copied successfully!</p>
      </div>

      <div className="entry">
        <div
          className="img"
          onClick={() => {
            setviewerInitHeight(!openViewer ? "auto" : 0);
            setOpenViewer(!openViewer);
          }}
        >
          <ReactImageFallback
            src={`https://testproduct.baetes.com/${data.slug}/preview/preview_128.png`}
            fallbackImage={placeholder}
            alt=""
          />
          <div className="overlay">
            <i className="fas fa-eye"></i>
          </div>
        </div>
        <div className="group">
          <p className="label">Company</p>
          {companyEditor ? (
            <div className="editor">
              <input
                type="text"
                ref={companyInp}
                onChange={(e) => {
                  setAvailableCompanies(
                    companiesListing.search(e.target.value)
                  );

                  setCurrentCompanyText(e.target.value);
                }}
                value={currentCompanyText}
              />
              <i
                className="fas fa-times"
                onClick={() => setCompanyEditor(false)}
              ></i>
              <div className="suggestions">
                {(currentCompanyText ? availableCompanies : companies).map(
                  (entry) => {
                    const item = entry.item ?? entry;
                    return (
                      <li
                        onClick={() => {
                          setCurrentCompanyText(item.name);
                          setSelectedCompanyID(JSON.stringify(item.id));
                          setAvailableCompanies([]);
                          setDetailsToBeChanged({
                            refName: companyInp.current,
                            setTextState: setCompanyName,
                            inputToClose: setCompanyEditor,
                            type: "company",
                          });
                          setOpenConfirmModal(true);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  }
                )}
              </div>
            </div>
          ) : (
            <div className="name-holder">
              <p className="value">{companyName}</p>
              <i
                className="fas fa-pen"
                onClick={() => setCompanyEditor(true)}
              ></i>
            </div>
          )}
        </div>
        <div className="group">
          <p className="label">Creation Date</p>
          <p className="value">
            {moment(data.created_at).format("MMM D, YYYY")}
          </p>
        </div>
        <div className="group">
          <CopyToClipboard
            text={
              `http://testproduct.baetes.com/${data.slug}` +
              (allowedParams.filter((entry) => entry !== null).length > 0
                ? "/?" +
                  allowedParams.filter((entry) => entry !== null).join("&")
                : "")
            }
            onCopy={() => copySuccess()}
          >
            <div
              className={"copy-btn " + (copy === "Copied!" ? "active" : "")}
              title={copy}
            >
              <p className="label">Deployment ID</p>
              <p className="value">{data.slug}</p>

              <div
                className="open-copy-settings"
                onClick={() => copySettings(true)}
              >
                <i className="fas fa-caret-up"></i> Click to edit URL tags
              </div>
            </div>
          </CopyToClipboard>
        </div>
        {/* <div className="group">
          <p className="label">Uploaded By</p>
          <p className="value">Alex Baetes</p>
        </div> */}
        <div className="group">
          <p className="label">Comment</p>
          <div
            className="btn"
            onClick={() => {
              setHeight(!openComment ? "auto" : 0);
              setOpenComment(!openComment);
            }}
          >
            {openComment ? "Hide" : "View"}
          </div>
        </div>
        <div className="group">
          <p className="label">Status</p>
          <p className="value">{data.status ?? "N/A"}</p>
        </div>
        {/* <div className="group">
          <p className="label">Activity</p>
          <div className="progress-bar"></div>
          <p className="updated-note">Last opened seconds ago</p>
        </div> */}
        <div className="group">
          <div
            className="action-btn"
            onClick={() => setActionList(!actionList)}
          >
            Actions <i className="fas fa-caret-down"></i>
            {actionList ? (
              <div className="actions-list">
                <Link to={`/${data.slug}/?name=${productName}`}>
                  <div className="act-btn">Edit 3D Viewer</div>
                </Link>
                <Link to={`/tools/hotspots/${data.slug}`}>
                  <div className="act-btn">Edit Hotspots</div>
                </Link>
                <div className="act-btn" onClick={() => downloadDep()}>
                  Download Deployment
                </div>
                <div
                  className="act-btn"
                  onClick={async () => {
                    await dispatch(
                      fetchDeploymentPreview(
                        "dev",
                        false,
                        data.slug,
                        user.id,
                        null
                      )
                    );
                    clearDeps();
                    getCurrentProdDeps();
                  }}
                >
                  Generate Preview
                </div>
                <div
                  className="act-btn"
                  onClick={() =>
                    setChangeDepMod({
                      active: true,
                      deploymentID: data.id,
                      deploymentSlug: data.slug,
                      currentProd: productName,
                    })
                  }
                >
                  Change Product
                </div>
                <div className="act-btn delete">
                  <p
                    className="del-btn"
                    onClick={() => setOpenDangerModal(true)}
                  >
                    Delete Deployment
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <AnimateHeight
        className={"comment-display " + (openComment ? "active" : "")}
        height={height}
        duration={500}
      >
        <p className="count">{commentCount?.length}/255</p>
        <p className="title">
          <div
            className="save-comment"
            onClick={() => {
              setDetailsToBeChanged({
                refName: commentInp.current,
                setTextState: setComment,
                inputToClose: setCommentEditor,
                type: "comment",
              });
              setOpenConfirmModal(true);
            }}
          >
            Save
          </div>
        </p>
        <div className="editor-input">
          <textarea
            defaultValue={comment}
            maxLength="255"
            ref={commentInp}
            onChange={(e) => setCommentCount(e.target.value)}
          />
        </div>
      </AnimateHeight>
      <AnimateHeight
        className="entry-viewer"
        height={viewerInitHeight}
        duration={500}
      >
        {openViewer ? (
          <iframe
            src={`https://testproduct.baetes.com/${data.slug}`}
            frameborder="0"
            title={deploymentID}
            className={"viewer-iframe " + viewerSize}
          ></iframe>
        ) : null}
        <div className="viewer-resizer">
          <div
            className={"btn " + (viewerSize === "small" ? "active" : null)}
            onClick={() => setviewerSize("small")}
          >
            Small
          </div>
          <div
            className={"btn " + (viewerSize === "medium" ? "active" : null)}
            onClick={() => setviewerSize("medium")}
          >
            Medium
          </div>
          <div
            className={"btn " + (viewerSize === "large" ? "active" : null)}
            onClick={() => setviewerSize("large")}
          >
            Large
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
}

export default CustomerEntry;
