import React, { useState } from "react";
import { ChromePicker } from "react-color";

function ViewerScreen({
  showOpenSetting,
  updateViewer,
  currentSettings,
  getCurrentSettings,
}) {
  const [color, setColor] = useState(currentSettings?.viewer?.backgroundColor);

  return (
    <div className={"active-setting-panel " + (showOpenSetting ? "show" : "")}>
      <div className="setting-entry background-changer">
        <p className="label">Background</p>
        <style>
          {`
            .background-changer .chrome-picker {
              background-color: ${color} !important;
            }
          `}
        </style>
        <div className="single-selectors">
          <div
            className={
              "btn " + (currentSettings?.viewer?.transparent ? "active" : "")
            }
            onClick={() => {
              updateViewer("background", "transparent");
              getCurrentSettings();
            }}
          >
            Transparent
          </div>
          <div
            className={
              "btn " + (currentSettings.viewer.gradient ? "active" : "")
            }
            onClick={() => {
              updateViewer("background", "gradient");
              getCurrentSettings();
            }}
          >
            Gradient
          </div>
          <div
            className={
              "btn " +
              (!currentSettings.viewer.gradient &&
              !currentSettings.viewer.transparent
                ? "active"
                : "")
            }
            onClick={() => {
              updateViewer("background", "backgroundColor");
              getCurrentSettings();
            }}
          >
            Solid color
          </div>
        </div>
        {!currentSettings.viewer.gradient &&
        !currentSettings.viewer.transparent ? (
          <ChromePicker
            color={color}
            disableAlpha={true}
            onChange={(color) => {
              updateViewer("background", "backgroundColor", color.hex);
              setColor(color.hex);
            }}
          />
        ) : null}
      </div>

      <div className="setting-entry">
        <div className="on-off-list">
          <div className="entry">
            <p className="label">3D Icon</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="touch"
                onChange={(e) => updateViewer("icon3D", e.target.checked)}
                defaultChecked={currentSettings.viewer.touch3d}
              />
              <label for="touch">Toggle</label>
            </div>
          </div>
          <div className="entry">
            <p className="label">Fullscreen Icon</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="hide-fullscreen"
                onChange={(e) =>
                  updateViewer("hideFullscreen", !e.target.checked)
                }
                defaultChecked={!currentSettings.viewer.hideFullscreen}
              />
              <label for="hide-fullscreen">Toggle</label>
            </div>
          </div>
          {/* <div className="entry">
            <p className="label">Hide Info</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="hide-info"
                onChange={(e) => updateViewer("hideInfo", e.target.checked)}
                defaultChecked={currentSettings.viewer.hideInfo}
              />
              <label for="hide-info">Toggle</label>
            </div>
          </div> */}
          <div className="entry">
            <p className="label">Logo</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="hide-logo"
                onChange={(e) => updateViewer("hideLogos", !e.target.checked)}
                defaultChecked={!currentSettings.viewer.hideLogos}
              />
              <label for="hide-logo">Toggle</label>
            </div>
          </div>
          {/* <div className="entry">
            <p className="label">Autoload</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="autoload"
                onChange={(e) => updateViewer("autoload", e.target.checked)}
                defaultChecked={currentSettings.viewer.autoload}
              />
              <label for="autoload">Toggle</label>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ViewerScreen;
