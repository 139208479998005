import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/App.css";
import AnimateHeight from "react-animate-height";
import ConfirmModal from "../shared/Modal";
import moment from "moment";
import Fuse from "fuse.js";
import CustomerEntry from "./CustomerEntry";
import {
  getProductDeployments,
  updateProduct,
  deleteProduct
} from "../../actions/ProductActions";
import placeholder from "../../images/logo-placeholder.png";
import ReactImageFallback from "react-image-fallback";

function SingleProduct({
  details,
  copySettings,
  userCopySettings,
  setChangeDepMod,
  sort,
  position,
  dotMenu,
  openDotMenu
}) {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);
  const companies = useSelector(({ Companies }) => Companies.companies);

  // States
  const [openProduct, setOpenProduct] = useState(false);
  const [height, setHeight] = useState(110);
  const [editProdName, setEditProdName] = useState(false);
  const [productName, setProductName] = useState(details.product.name);
  const [brand, setBrand] = useState(details.product.brand);
  const [article, setArticle] = useState(details.product.article_number);
  const [style, setStyle] = useState(details.product.style);
  const [type, setType] = useState(details.product.type);
  const [openModal, setOpenModal] = useState(false);
  const [editBrandField, setEditBrandField] = useState(false);
  const [editArticleField, setEditArticleField] = useState(false);
  const [editStyleField, setEditStyleField] = useState(false);
  const [editTypeField, setEditTypeField] = useState(false);
  const [detailsToBeChanged, setDetailsToBeChanged] = useState({
    refName: null,
    setTextState: null,
    inputToClose: null,
    entryToUpdate: null,
    id: null
  });
  const [currentDeployment, setCurrentDeployment] = useState([]);
  const [brandSuggestion, setBrandSuggestion] = useState(false);
  const [availableCompanies, setAvailableCompanies] = useState([]);

  // Refs
  const prodNameInp = React.createRef();
  const brandInp = React.createRef();
  const articleInp = React.createRef();
  const styleInp = React.createRef();
  const typeInp = React.createRef();

  const acceptInputEdits = () => {
    const {
      refName,
      setTextState,
      inputToClose,
      entryToUpdate,
      id
    } = detailsToBeChanged;
    setTextState(refName.value);
    inputToClose(false);
    setOpenModal(false);
    dispatch(
      updateProduct(id, user.id, {
        [entryToUpdate]: refName.value
      })
    );
  };

  const companiesListing = new Fuse(companies, {
    keys: ["name", "id"],
    threshold: 0.2
  });

  const getCurrentProdDeps = async () => {
    const newDeployments = await dispatch(
      getProductDeployments(user.id, details.product.id)
    );
    setCurrentDeployment(newDeployments);
  };

  return (
    <AnimateHeight
      className={"product " + (openProduct ? "active" : "")}
      height={height}
      duration={500}
      style={{ zIndex: 10000 - position }}
    >
      {openModal ? (
        <ConfirmModal
          yes={() => acceptInputEdits()}
          no={() => setOpenModal(false)}
          text="Are you sure you want to confirm changes to this field and save?"
          icon="exclamation-triangle warn"
        />
      ) : null}

      <div className="top-side">
        <div className="dots-menu">
          <i
            className="fas fa-ellipsis-v menu-btn"
            onClick={() =>
              openDotMenu(
                dotMenu === details.product.id ? "" : details.product.id
              )
            }
          ></i>
          {dotMenu === details.product.id ? (
            <div className="h-menu">
              {details.deployment_count ? (
                <div className="menu-item no-option-btn">
                  <p>No Options</p>
                </div>
              ) : (
                <div
                  className="menu-item delete-btn"
                  onClick={() =>
                    dispatch(deleteProduct(user.id, sort, details.product.id))
                  }
                >
                  <p>Delete Product</p>
                </div>
              )}
            </div>
          ) : null}
        </div>

        <div
          className={(openProduct ? "active" : "") + " expander-icon"}
          onClick={async () => {
            await getCurrentProdDeps();
            setHeight(!openProduct ? "auto" : 110 + (brandSuggestion ? 80 : 0));
            setOpenProduct(!openProduct);
          }}
        >
          <i className="fas fa-caret-right"></i>
        </div>
        <div className="prod-img">
          <ReactImageFallback
            src={`https://testproduct.baetes.com/${details.product.preview.slug}/preview/preview_128.png`}
            fallbackImage={placeholder}
            alt=""
          />
        </div>
        <div className="prod-details">
          {editProdName ? (
            <div className="editor-holder">
              <input
                className="edit-input"
                type="text"
                defaultValue={productName}
                ref={prodNameInp}
              />
              <i
                className="fas fa-times"
                onClick={() => setEditProdName(false)}
              ></i>
              <i
                className="fas fa-check"
                onClick={() => {
                  setDetailsToBeChanged({
                    refName: prodNameInp.current,
                    setTextState: setProductName,
                    inputToClose: setEditProdName,
                    entryToUpdate: "name",
                    id: details.product.id
                  });
                  setOpenModal(true);
                }}
              ></i>
            </div>
          ) : (
            <p className="title">
              {productName}{" "}
              <i
                className="fas fa-pen"
                onClick={() => setEditProdName(true)}
              ></i>
            </p>
          )}
          <div className="details">
            <div className="group">
              <p className="label">Brand Name</p>
              {editBrandField ? (
                <div className="editfield">
                  <input
                    type="text"
                    value={brand}
                    ref={brandInp}
                    onChange={(e) => {
                      setAvailableCompanies(
                        companiesListing.search(e.target.value)
                      );
                      setBrand(e.target.value);
                      setBrandSuggestion(true);
                      !openProduct ? setHeight(190) : setHeight("auto");
                    }}
                  />
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setEditBrandField(false);
                      !openProduct ? setHeight(110) : setHeight("auto");
                      setBrandSuggestion(false);
                      setBrand(details.product.brand);
                    }}
                  ></i>
                  {brandSuggestion ? (
                    <div className="suggestions">
                      {(availableCompanies.length > 0
                        ? availableCompanies
                        : companies
                      ).map((entry) => {
                        const item = entry.item ?? entry;
                        return (
                          <li
                            onClick={() => {
                              setBrand(item.name);
                              setDetailsToBeChanged({
                                refName: brandInp.current,
                                setTextState: setBrand,
                                inputToClose: setEditBrandField,
                                entryToUpdate: "brand",
                                id: details.product.id
                              });
                              setOpenModal(true);
                              !openProduct ? setHeight(110) : setHeight("auto");
                              setBrandSuggestion(false);
                            }}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              ) : (
                <p className="value">
                  {brand}{" "}
                  <i
                    className="fas fa-pen"
                    onClick={() => {
                      setEditBrandField(true);
                      setBrandSuggestion(true);
                      !openProduct ? setHeight(height + 80) : setHeight("auto");
                    }}
                  ></i>
                </p>
              )}
            </div>
            <div className="group">
              <p className="label">Creation Date</p>
              <p className="value">
                {moment(details.product.date_added).format("MMM DD, YYYY")}
              </p>
            </div>
            <div className="group">
              <p className="label">Deployments</p>
              <p className="value">{details.deployment_count}</p>
            </div>
            <div className="group">
              <p className="label">Customers</p>
              <p className="value">{details.company_count}</p>
            </div>
            <div className="group">
              <p className="label">Style</p>
              {editStyleField ? (
                <div className="editfield">
                  <input type="text" defaultValue={style} ref={styleInp} />
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setEditStyleField(false);
                    }}
                  ></i>
                  <i
                    className="fas fa-check"
                    onClick={() => {
                      setDetailsToBeChanged({
                        refName: styleInp.current,
                        setTextState: setStyle,
                        inputToClose: setEditStyleField,
                        entryToUpdate: "style",
                        id: details.product.id
                      });
                      setOpenModal(true);
                    }}
                  ></i>
                </div>
              ) : (
                <p className="value">
                  {style}{" "}
                  <i
                    className="fas fa-pen"
                    onClick={() => setEditStyleField(true)}
                  ></i>
                </p>
              )}
            </div>
            <div className="group">
              <p className="label">Object Type</p>
              {editTypeField ? (
                <div className="editfield">
                  <select name="objectType" defaultValue={type} ref={typeInp}>
                    <option value="Clothing">Clothing</option>
                    <option value="Shoe">Shoe</option>
                    <option value="Hat">Hat</option>
                  </select>
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setEditTypeField(false);
                    }}
                  ></i>
                  <i
                    className="fas fa-check"
                    onClick={() => {
                      setDetailsToBeChanged({
                        refName: typeInp.current,
                        setTextState: setType,
                        inputToClose: setEditTypeField,
                        entryToUpdate: "type",
                        id: details.product.id
                      });
                      setOpenModal(true);
                    }}
                  ></i>
                </div>
              ) : (
                <p className="value">
                  {type}{" "}
                  <i
                    className="fas fa-pen"
                    onClick={() => setEditTypeField(true)}
                  ></i>
                </p>
              )}
            </div>
            <div className="group">
              <p className="label">Article No.</p>
              {editArticleField ? (
                <div className="editfield">
                  <input type="text" defaultValue={article} ref={articleInp} />
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      setEditArticleField(false);
                    }}
                  ></i>
                  <i
                    className="fas fa-check"
                    onClick={() => {
                      setDetailsToBeChanged({
                        refName: articleInp.current,
                        setTextState: setArticle,
                        inputToClose: setEditArticleField,
                        entryToUpdate: "article_number",
                        id: details.product.id
                      });
                      setOpenModal(true);
                    }}
                  ></i>
                </div>
              ) : (
                <p className="value">
                  {article}{" "}
                  <i
                    className="fas fa-pen"
                    onClick={() => setEditArticleField(true)}
                  ></i>
                </p>
              )}
            </div>
            {/* <div className="group">
              <p className="label">Status</p>
              <p className="value">Active</p>
            </div> */}
          </div>
        </div>
      </div>

      <AnimateHeight
        className="customer-groups"
        height={!openProduct ? 0 : "auto"}
        duration={500}
      >
        {currentDeployment.length ? (
          currentDeployment.map((company) => (
            <div className="customer">
              {company.deployments
                ?.sort((a, b) =>
                  moment(a.created_at).isAfter(b.created_at)
                    ? -1
                    : moment(a.created_at).isBefore(b.created_at)
                    ? 1
                    : 0
                )
                .map((entry) => (
                  <CustomerEntry
                    data={entry}
                    productID={details.product.id}
                    productName={productName}
                    brandName={details.product.brand}
                    styleName={style}
                    objectName={type}
                    articleName={article}
                    copySettings={(value) => copySettings(value)}
                    userCopySettings={userCopySettings}
                    company={company.company?.name}
                    setChangeDepMod={setChangeDepMod}
                    getCurrentProdDeps={() => getCurrentProdDeps()}
                    clearDeps={() => setCurrentDeployment([])}
                  />
                ))}
            </div>
          ))
        ) : (
          <p className="no-data">No Deployments for Product</p>
        )}
      </AnimateHeight>
    </AnimateHeight>
  );
}

export default SingleProduct;
