import React from "react";
import { Link } from "react-router-dom";
import "../styles/App.css";

function Tools() {
  return (
    <div className="tools-panel">
      <div className="cards">
        <Link to="/tools/hotspots">
          <div className="card">
            <div className="icon">
              <i className="fab fa-hotjar"></i>
            </div>
            <div className="label">
              <p>Hotspots</p>
            </div>
          </div>
        </Link>

        <Link to="/tools/preview-generator">
          <div className="card">
            <div className="icon">
              <i className="fas fa-image"></i>
            </div>
            <div className="label">
              <p>Preview Generator</p>
            </div>
          </div>
        </Link>

        <a
          href="https://docs.baetes.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card">
            <div className="icon">
              <i className="fas fa-book"></i>
            </div>
            <div className="label">
              <p>Documentation</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Tools;
