import Http from "axios";

export const ACTIONS = {
  SET_PRODUCTS: "SET_PRODUCTS",
  LOAD_PRODUCTS: "LOAD_PRODUCTS",
  SET_DEPLOYMENTS: "SET_DEPLOYMENTS",
  CREATED_PRODUCT: "CREATED_PRODUCT",
};

export const getAllProducts = (userID, sort) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products?sort=${sort}`
    );
    dispatch({
      data: data,
      type: ACTIONS.SET_PRODUCTS,
    });
  } catch (err) {
    throw err;
  }
};

export const deleteProduct = (userID, sort, prodID) => async (dispatch) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/${prodID}/delete`,
      { product_id: prodID.toString() }
    );

    getAllProducts(userID, sort)(dispatch);
  } catch (err) {
    throw err;
  }
};

export const getSearchedForProducts = (
  userID,
  sort,
  searchTerm,
  searchBy
) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products?sort=${sort}&search_by=${searchBy}&search_for=${encodeURIComponent(
        searchTerm
      )}`
    );
    dispatch({
      data: data,
      type: ACTIONS.SET_PRODUCTS,
    });
  } catch (err) {
    throw err;
  }
};

export const clearProducts = () => async (dispatch) => {
  dispatch({
    data: { products: [] },
    type: ACTIONS.SET_PRODUCTS,
  });
};

export const updateProduct = (productId, userID, updatedFields) => async () => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/${productId}/update`,
      updatedFields
    );
  } catch (err) {
    throw err;
  }
};

export const updateDeploymentCompany = (
  deploymentId,
  productId,
  userID,
  name
) => async () => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/${productId}/deployments/${deploymentId}/update-company`,
      { company_id: name }
    );
  } catch (err) {
    throw err;
  }
};

export const updateDeploymentByID = (
  deploymentId,
  productId,
  userID,
  updateField
) => async () => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/${productId}/deployments/${deploymentId}/update`,
      updateField
    );
  } catch (err) {
    throw err;
  }
};

export const loadProducts = (
  userID,
  page,
  withSearch,
  sort,
  searchBy,
  searchTerm
) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products?page=${page}&sort=${sort}${
        withSearch
          ? `&search_by=${searchBy}&search_for=${encodeURIComponent(
              searchTerm
            )}`
          : ""
      }`
    );
    dispatch({
      data: data,
      type: ACTIONS.LOAD_PRODUCTS,
    });
  } catch (err) {
    throw err;
  }
};

export const createProduct = (
  name,
  brand,
  style,
  object,
  article,
  userID
) => async (dispatch) => {
  try {
    const { data } = await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/create`,
      {
        name: name,
        brand: brand,
        style: style,
        type: object,
        article_number: article,
      }
    );
    dispatch({
      data: data,
      type: ACTIONS.CREATED_PRODUCT,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export const getProductDeployments = (userID, prodID) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/${prodID}/deployments`,
      {
        withCredentials: false,
      }
    );
    dispatch({
      data: data,
      type: ACTIONS.SET_DEPLOYMENTS,
    });
    return data ? data : [];
  } catch (err) {
    throw err;
  }
};

export const updateDeployments = (
  newSettings,
  deploymentId,
  userID
) => async () => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/deployments/${deploymentId}/update_viewer`,
      {
        deploymentId,
        version: "dev_1.1.4",
        env: "prod",
        settings: newSettings,
      }
    );
  } catch (err) {
    throw err;
  }
};

export const downloadDeployment = (
  productId,
  deploymentId,
  deploymentSlug,
  userID
) => async () => {
  try {
    const { data } = await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/${productId}/deployments/${deploymentId}/download`,
      {
        deployment_slug: deploymentSlug,
      }
    );

    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchDeploymentPreview = (
  env,
  download,
  deploymentSlug,
  userID,
  resolutions
) => async () => {
  try {
    const { data } = await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/products/deployments/preview`,
      {
        env,
        deployments: [
          {
            deployment_slug: deploymentSlug,
            download,
            resolutions,
          },
        ],
      }
    );

    return data;
  } catch (err) {
    throw err;
  }
};
