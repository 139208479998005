import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/App.css";
import { fetchDeploymentPreview } from "../actions/ProductActions";

function PreviewGenerator() {
  const user = useSelector(({ User }) => User.user);
  const dispatch = useDispatch();

  const [deploymentID, setDeploymentID] = useState();
  const [resolutions, setResolutions] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const addNewResloution = () => {
    setResolutions([...resolutions, { id: resolutions.length, x: 0, y: 0 }]);
  };

  const removeResloution = (id) => {
    const filteredResolutions = resolutions.filter((entry) =>
      entry.id !== id ? entry : null
    );

    console.log("selected id: " + id, resolutions);

    setResolutions(filteredResolutions);
  };

  const changeResolution = (id, field, value) => {
    setResolutions(
      resolutions.map((res) =>
        res.id === id
          ? {
              ...resolutions[id],
              [field]: value,
            }
          : res
      )
    );
  };

  const downloadPreviews = async () => {
    setShowLoading(true);
    const cbData = await dispatch(
      fetchDeploymentPreview("dev", true, deploymentID, user.id, resolutions)
    );
    setShowLoading(false);
    window.location.href = JSON.parse(cbData.result)[deploymentID];
  };

  return (
    <div className="preview-panel">
      {showLoading ? (
        <div className="download-loading">
          <div class="lds-hourglass"></div>
        </div>
      ) : null}
      <div className="generator">
        <div className="head">Preview Generator</div>
        <div className="body">
          <div className="input-group">
            <p>Deployment ID</p>
            <input
              type="text"
              onChange={(e) => setDeploymentID(e.target.value)}
            />
          </div>
          <p className="label">Add desired resolutions (in pixels)</p>

          {resolutions.map((entry, key) => (
            <div className="new-resolution" key={key}>
              <i
                className="fas fa-times delete-btn"
                onClick={() => removeResloution(entry.id)}
              ></i>
              <div className="res-input">
                <p>W</p>
                <input
                  type="number"
                  placeholder="Width"
                  value={entry.width}
                  onChange={(e) =>
                    changeResolution(entry.id, "x", e.target.value)
                  }
                />
              </div>
              <div className="times">
                <i className="fas fa-times"></i>
              </div>
              <div className="res-input">
                <p>H</p>
                <input
                  type="number"
                  placeholder="Height"
                  value={entry.height}
                  onChange={(e) =>
                    changeResolution(entry.id, "y", e.target.value)
                  }
                />
              </div>
            </div>
          ))}

          <div className="entry-add" onClick={() => addNewResloution()}>
            <i className="fas fa-plus"></i>
            <p>Add new resolution</p>
          </div>

          <div
            className="download-btn"
            onClick={async () => {
              await downloadPreviews();
            }}
          >
            Generate and Download
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewGenerator;
