import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "../../../../../styles/Slider.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const SimpleSlider = createSliderWithTooltip(Slider);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip>
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

function CameraScreen({
  showOpenSetting,
  currentSettings,
  updateViewer,
  getCurrentPosition,
}) {
  const [polarAngle, setPolarAngle] = useState([
    currentSettings.camera.minPolarAngle,
    currentSettings.camera.maxPolarAngle,
  ]);
  const [zoom, setZoom] = useState([
    currentSettings.camera.zoom.min,
    currentSettings.camera.zoom.max,
  ]);
  const [FOV, setFOV] = useState(currentSettings.camera.fov);

  const [defaultCamera, setDefaultCamera] = useState({
    r: currentSettings.camera.defaultCam.x,
    p: currentSettings.camera.defaultCam.y,
    t: currentSettings.camera.defaultCam.z,
  });

  return (
    <div className={"active-setting-panel " + (showOpenSetting ? "show" : "")}>
      <div className="setting-entry">
        <p className="label">Polar Angle Min/Max</p>
        <Range
          min={-90}
          max={90}
          step={1}
          marks={{
            [-90]: "-90°",
            90: "90°",
          }}
          onChange={(value) => {
            setPolarAngle(value);
            updateViewer("polarAngle", { min: value[0], max: value[1] });
          }}
          defaultValue={polarAngle}
          handle={handle}
          pushable
        />
      </div>

      <div className="setting-entry">
        <p className="label">Zoom Min/Max</p>
        <Range
          min={0}
          max={1000}
          step={1}
          marks={{
            0: "0",
            1000: "1000",
          }}
          onChange={(value) => {
            setZoom(value);
            updateViewer("zoom", { min: value[0], max: value[1] });
          }}
          defaultValue={zoom}
          handle={handle}
          pushable
        />
      </div>

      <div className="setting-entry">
        <p className="label">FOV</p>
        <SimpleSlider
          min={1}
          max={60}
          step={1}
          marks={{
            1: "1",
            60: "60",
          }}
          onChange={(value) => {
            setFOV(value);
            updateViewer("fov", value);
          }}
          defaultValue={FOV}
          handle={handle}
        />
      </div>

      <div className="setting-entry">
        <p className="label">Default Camera</p>
        <div className="xyz-inputs">
          <div className="group">
            <p>Radius </p>
            <Range
              min={zoom[0]}
              max={zoom[1]}
              step={1}
              marks={{
                [zoom[0]]: zoom[0],
                [zoom[1]]: zoom[1],
              }}
              value={[defaultCamera.r]}
              onChange={(e) => {
                setDefaultCamera({
                  ...defaultCamera,
                  r: parseFloat(e),
                });

                updateViewer("defaultCam", {
                  ...defaultCamera,
                  r: parseFloat(e),
                });
              }}
              handle={handle}
              style={{ marginBottom: 40 }}
            />
          </div>
          <div className="group">
            <p>Azimuth </p>
            <Range
              min={-180}
              max={180}
              step={1}
              marks={{
                [-180]: "-180°",
                180: "180°",
              }}
              value={[defaultCamera.p]}
              onChange={(e) => {
                setDefaultCamera({
                  ...defaultCamera,
                  p: parseFloat(e),
                });

                updateViewer("defaultCam", {
                  ...defaultCamera,
                  p: parseFloat(e),
                });
              }}
              handle={handle}
              style={{ marginBottom: 40 }}
            />
          </div>
          <div className="group">
            <p>Elevation </p>
            <Range
              min={polarAngle[0]}
              max={polarAngle[1]}
              step={1}
              marks={{
                [polarAngle[0]]: polarAngle[0] + "°",
                [polarAngle[1]]: polarAngle[1] + "°",
              }}
              value={[defaultCamera.t]}
              onChange={(e) => {
                setDefaultCamera({
                  ...defaultCamera,
                  t: parseFloat(e),
                });

                updateViewer("defaultCam", {
                  ...defaultCamera,
                  t: parseFloat(e),
                });
              }}
              handle={handle}
              style={{ marginBottom: 40 }}
            />
          </div>
        </div>
        <div
          className="get-current-btn"
          onClick={async () => {
            const { r, p, t } = await getCurrentPosition();

            setDefaultCamera({
              r,
              p,
              t,
            });

            updateViewer("defaultCam", {
              r,
              p,
              t,
            });
          }}
        >
          <p>Get Current Coordinates</p>
        </div>
      </div>

      <div className="setting-entry">
        <div className="on-off-list">
          <div className="entry">
            <p className="label">Disable Pan</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="pan"
                onChange={(e) => updateViewer("disablePan", e.target.checked)}
                defaultChecked={currentSettings.camera.disablePan}
              />
              <label for="pan">Toggle</label>
            </div>
          </div>
          <div className="entry">
            <p className="label">Disable Rotate</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="rotate"
                onChange={(e) =>
                  updateViewer("disableRotate", e.target.checked)
                }
                defaultChecked={currentSettings.camera.disableRotate}
              />
              <label for="rotate">Toggle</label>
            </div>
          </div>
          <div className="entry">
            <p className="label">Disable Zoom</p>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="zoom"
                onChange={(e) => updateViewer("disableZoom", e.target.checked)}
                defaultChecked={currentSettings.camera.disableZoom}
              />
              <label for="zoom">Toggle</label>
            </div>
          </div>
          {/* <div className="entry">
            <p className="label">Camera Type</p>
            <div className="toggle-switch">
              <select name="" id="">
                <option value="orthographic">Orthographic</option>
                <option value="perspective">Perspective</option>
              </select>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CameraScreen;
