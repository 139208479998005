import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ViewerScreen from "./panelScreens/viewer";
import CameraScreen from "./panelScreens/camera";
import LightingScreen from "./panelScreens/lighting";
import ModelScreen from "./panelScreens/model";
import Modal from "../../../shared/Modal";
import {
  updateDeployments,
  fetchDeploymentPreview,
} from "../../../../actions/ProductActions";

function SingleProduct({
  settingsOpen,
  currentSettings,
  getCurrentSettings,
  getCurrentPosition,
  setSettingsOpen,
  toggleSettings,
}) {
  const [showOpenSetting, setShowOpenSetting] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [lightingOpen, setLightingOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [openAdvancedMenu, setOpenAdvancedMenu] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState("");
  const [SettingsType, setSettingsType] = useState("normal");

  const updateViewer = (setting, value, color) => {
    document.querySelector(".viewer-iframe").contentWindow.postMessage(
      JSON.stringify({
        type: "updateSettings",
        data: {
          setting,
          value,
          color,
        },
      }),
      "*"
    );
  };

  const user = useSelector(({ User }) => User.user);

  const dispatch = useDispatch();
  const params = useParams();
  const deploymentID = params.id?.split("/")[0];

  const saveSuccessNotifier = () => {
    setSaveSuccess(true);

    setTimeout(() => {
      setSaveSuccess(false);
    }, 3000);
  };

  const normalUpdate = async () => {
    dispatch(updateDeployments(currentSettings, deploymentID, user.id));

    dispatch(fetchDeploymentPreview("dev", false, deploymentID, user.id, null));
  };

  const advancedUpdate = async () => {
    dispatch(
      updateDeployments(JSON.parse(advancedSettings), deploymentID, user.id)
    );
    dispatch(fetchDeploymentPreview("dev", false, deploymentID, user.id, null));
  };

  return (
    <div className={"settings-panel " + (settingsOpen ? "open" : null)}>
      <div
        className={"expander-btn " + (settingsOpen ? "open" : null)}
        onClick={toggleSettings}
      >
        {settingsOpen ? (
          <i className="fas fa-chevron-right"></i>
        ) : (
          <i className="fas fa-chevron-left"></i>
        )}
      </div>
      {openWarnModal ? (
        <Modal
          yes={() => {
            SettingsType === "normal" ? normalUpdate() : advancedUpdate();
            setOpenWarnModal(false);
            saveSuccessNotifier();
          }}
          no={() => setOpenWarnModal(false)}
          text="Are you sure you want to apply the new settings? this action will overwrite previous settings."
          icon="exclamation-triangle warn"
        />
      ) : null}

      {openAdvancedMenu ? (
        <div className="advanced-settings-mod">
          <div className="mod">
            <div className="head">
              <p>Advanced Settings</p>
            </div>
            <div className="content">
              <textarea
                defaultValue={
                  currentSettings
                    ? JSON.stringify(currentSettings, null, 2)
                    : null
                }
                onChange={(e) => setAdvancedSettings(e.target.value)}
              ></textarea>
            </div>
            <div className="btns">
              <div
                className="btn"
                onClick={() => {
                  setOpenAdvancedMenu(false);
                  setOpenWarnModal(true);
                }}
              >
                UPDATE SETTINGS
              </div>
              <div
                className="btn"
                onClick={() => {
                  setOpenAdvancedMenu(false);
                }}
              >
                CANCEL
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="head">
        {showOpenSetting ? (
          <i
            className="fas fa-chevron-left"
            onClick={() => {
              setShowOpenSetting(false);
              setViewerOpen(false);
              setCameraOpen(false);
              setLightingOpen(false);
              setModelOpen(false);
            }}
          ></i>
        ) : null}
        <p>
          {viewerOpen
            ? "Viewer Settings"
            : cameraOpen
            ? "Camera Settings"
            : lightingOpen
            ? "Lighting Settings"
            : "Settings Menu"}
        </p>
      </div>
      <div className={"menu " + (showOpenSetting ? "hide" : "")}>
        <li
          className="entry"
          onClick={() => {
            setShowOpenSetting(true);
            setViewerOpen(true);
          }}
        >
          Viewer <i className="fas fa-chevron-right"></i>
        </li>
        <li
          className="entry"
          onClick={() => {
            setShowOpenSetting(true);
            setCameraOpen(true);
          }}
        >
          Camera <i className="fas fa-chevron-right"></i>
        </li>
        <li
          className="entry"
          onClick={() => {
            setShowOpenSetting(true);
            setLightingOpen(true);
          }}
        >
          Lighting <i className="fas fa-chevron-right"></i>
        </li>
        <li
          className="entry"
          onClick={() => {
            setShowOpenSetting(true);
            setModelOpen(true);
          }}
        >
          Model <i className="fas fa-chevron-right"></i>
        </li>
        <li
          className="entry close-panel-btn"
          onClick={() => {
            setSettingsOpen();
          }}
        >
          Close Panel
        </li>
      </div>
      {currentSettings ? (
        <>
          <ViewerScreen
            showOpenSetting={viewerOpen}
            currentSettings={currentSettings}
            updateViewer={(setting, value, color) =>
              updateViewer(setting, value, color)
            }
            getCurrentSettings={() => getCurrentSettings()}
          />
          <CameraScreen
            showOpenSetting={cameraOpen}
            currentSettings={currentSettings}
            updateViewer={(setting, value) => updateViewer(setting, value)}
            getCurrentPosition={() => getCurrentPosition()}
          />
          <LightingScreen
            showOpenSetting={lightingOpen}
            currentSettings={currentSettings}
            updateViewer={(setting, value) => updateViewer(setting, value)}
          />
          <ModelScreen
            showOpenSetting={modelOpen}
            currentSettings={currentSettings}
            updateViewer={(setting, value) => updateViewer(setting, value)}
          />
        </>
      ) : null}

      <div className="panel-bottom-btns">
        <div
          className={
            "update-settings " + (saveSuccess ? "saved-successfully" : "")
          }
          onClick={() => {
            getCurrentSettings();
            setOpenWarnModal(true);
            setSettingsType("normal");
          }}
        >
          <p>{saveSuccess ? "SAVED SUCCESSFULLY" : "UPDATE SETTINGS"}</p>
        </div>

        <i
          className="fas fa-cog advanced-editor"
          onClick={() => {
            getCurrentSettings();
            setOpenAdvancedMenu(true);
            setSettingsType("advanced");
          }}
        ></i>
      </div>
    </div>
  );
}

export default SingleProduct;
