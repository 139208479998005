import React from "react";
import "../../styles/App.css";

function Modal({ yes, no, text, icon }) {
  return (
    <div className="global-modal">
      <div className="overlay"></div>
      <div className="modal-box">
        <div className="icon">
          <i className={"fas fa-" + icon}></i>
        </div>
        <div className="text">
          <p>{text}</p>
        </div>
        <div className="action-btns">
          <div className="btn" onClick={no}>
            NO
          </div>
          <div className="btn" onClick={yes}>
            YES
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
