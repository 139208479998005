import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAccount } from "../../actions/UserActions";
import "../../styles/App.css";
import logo from "../../images/baetes_white.png";
import placeholder from "../../images/logo-placeholder.png";
import ReactImageFallback from "react-image-fallback";

function SideNav() {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);

  return (
    <div className="sidenav">
      {" "}
      <div className="logo-holder">
        {" "}
        <Link to="/">
          {" "}
          <img src={logo} alt="" />{" "}
        </Link>{" "}
      </div>{" "}
      <div className="profile-area">
        {" "}
        <ReactImageFallback
          src={
            user?.company_id === 0
              ? logo
              : `https://testportal.baetes.com/static/logos/${user?.company_id}.png`
          }
          fallbackImage={placeholder}
          alt=""
          className="avatar"
        />
        <p>
          {user ? user.username : ""} <br />{" "}
          <span>{user ? user.company_name : ""}</span>
        </p>{" "}
      </div>{" "}
      <div className="links-holder">
        {" "}
        {/* <NavLink to="/home" activeClassName="active">
          <i className="fas fa-home"></i> Home
        </NavLink> */}
        <NavLink to="/products" activeClassName="active">
          {" "}
          <i className="fas fa-cube"></i> Products{" "}
        </NavLink>{" "}
        <NavLink to="/companies" activeClassName="active">
          {" "}
          <i className="fas fa-building"></i> Companies{" "}
        </NavLink>{" "}
        <NavLink to="/tools" activeClassName="active">
          {" "}
          <i className="fas fa-toolbox"></i> Tools{" "}
        </NavLink>{" "}
        {user?.account_permissions.get_accounts ? (
          <NavLink to="/users" activeClassName="active">
            {" "}
            <i className="fas fa-users"></i> Users{" "}
          </NavLink>
        ) : null}{" "}
        <NavLink to="/profile" activeClassName="active">
          {" "}
          <i className="fas fa-user"></i> Profile{" "}
        </NavLink>{" "}
        <a href="/" onClick={() => dispatch(resetAccount())}>
          {" "}
          <i className="fas fa-sign-out-alt"></i> Logout{" "}
        </a>{" "}
      </div>{" "}
    </div>
  );
}

export default SideNav;
