import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "./store";

export const safeParseInt = (number) => {
  const parsedNumber = parseInt(number, 10);

  if (!parsedNumber) {
    return 0;
  }

  return parsedNumber;
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const account = store.getState().User;
      const expiredToken =
        !account?.accessTokenExpiration ||
        Date.now() > safeParseInt(account?.accessTokenExpiration, 10);
      console.log(
        "Private route account:",
        account,
        !expiredToken && account?.user
      );
      return !expiredToken && account?.user ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const account = store.getState().User;
      const expiredToken =
        !account?.accessTokenExpiration ||
        Date.now() > safeParseInt(account?.accessTokenExpiration, 10);
      console.log(
        "Public route account:",
        account,
        expiredToken,
        safeParseInt(account?.accessTokenExpiration, 10)
      );
      return !expiredToken && account?.user ? (
        <Redirect to="/products" />
      ) : (
        <Component {...props} />
      );
    }}
  />
);
