import Http from "axios";

export const ACTIONS = {
  SET_USER: "SET_USER",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_ACCESS_TOKEN_EXPIRATION: "SET_ACCESS_TOKEN_EXPIRATION",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
};

export const updateAccount = ({
  user,
  accessToken,
  accessTokenExpiration,
  refreshToken,
}) => (dispatch) => {
  if (user !== undefined) {
    dispatch({
      data: user,
      type: ACTIONS.SET_USER,
    });
  }

  if (accessToken !== undefined) {
    dispatch({
      data: accessToken,
      type: ACTIONS.SET_ACCESS_TOKEN,
    });
  }

  if (accessTokenExpiration !== undefined) {
    dispatch({
      data: accessTokenExpiration,
      type: ACTIONS.SET_ACCESS_TOKEN_EXPIRATION,
    });
  }

  if (refreshToken !== undefined) {
    dispatch({
      data: refreshToken,
      type: ACTIONS.SET_REFRESH_TOKEN,
    });
  }
};

export const resetAccount = () => (dispatch) => {
  updateAccount({
    user: null,
    accessToken: null,
    accessTokenExpiration: 0,
    refreshToken: null,
  })(dispatch);
};

export const login = ({ username, password }) => async (dispatch) => {
  try {
    const { data } = await Http.post(
      "https://testapi.baetes.com/api/v1/accounts/login",
      {
        username,
        password,
      }
    );
    updateAccount({
      user: data.account,
      accessToken: data.accessToken.key,
      accessTokenExpiration: Date.now() + data.accessToken.expires_in * 1000,
      refreshToken: data.refreshToken.key,
    })(dispatch);
  } catch (err) {
    throw err;
  }
};

export const tokenStatus = (id) => async (dispatch) => {
  try {
    const { data } = await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/tokens/status`,
      {
        id,
      }
    );
    updateAccount({
      accessToken: data.key,
      accessTokenExpiration: Date.now() + data.expires_in * 1000,
    })(dispatch);
  } catch (err) {
    updateAccount({
      user: null,
      accessToken: null,
      accessTokenExpiration: 0,
      refreshToken: null,
    })(dispatch);
  }
};
