import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../actions/ProductActions";
import "../styles/App.css";
import Mesh from "../components/wizard/Mesh";
import AnimateHeight from "react-animate-height";
import update from "immutability-helper";
import Fuse from "fuse.js";

function ProductWizard() {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.user);
  const products = useSelector(({ Products }) => Products.products);

  useEffect(() => {
    dispatch(getAllProducts(user.id, "newest"));
  }, []);

  const productsListing = new Fuse(products, {
    keys: ["product.name"],
    threshold: 0.2
  });

  const [minimizedPartWindow, setMinimizedPartWindow] = useState([]);
  const [minimizedMeshWindow, setMinimizedMeshWindow] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [productName, setProductName] = useState("Product name here");
  const [productID, setProductID] = useState();
  const [editorMode, setEditorMode] = useState({
    field: null,
    partKey: null,
    meshKey: null,
    materialKey: null,
    inputValue: null
  });

  const [productParts, setProductParts] = useState([
    {
      name: "Part 1",
      meshes: [
        {
          id: Date.now(),
          name: "Mesh 1",
          filename: "",
          file: "",
          albedos: [],
          materials: [
            {
              name: "Base Material",
              availableTextures: [
                "Normal",
                "Metallic",
                "AO",
                "Roughness",
                "Alpha"
              ],
              selectedTextures: []
            }
          ]
        }
      ]
    }
  ]);

  const closeEditor = () => {
    setEditorMode({
      field: null,
      partKey: null,
      meshKey: null,
      materialKey: null,
      inputValue: null
    });
  };

  const addPart = () => {
    setProductParts([
      ...productParts,
      {
        name: "Part " + (productParts.length + 1),
        meshes: [
          {
            id: Date.now(),
            name: "Mesh base",
            filename: "",
            file: "",
            albedos: [],
            materials: [
              {
                name: "Material base",
                availableTextures: [
                  "Normal",
                  "Metallic",
                  "AO",
                  "Roughness",
                  "Alpha"
                ],
                selectedTextures: []
              }
            ]
          }
        ]
      }
    ]);
  };

  const deletePart = (partIndex) => {
    const ModifiedParts = productParts.filter((part, key) => key !== partIndex);

    setProductParts(ModifiedParts);
  };

  const addMesh = (index) => {
    setProductParts(
      productParts.map((part, key) =>
        key === index
          ? {
              ...part,
              meshes: [
                ...part.meshes,
                {
                  id: Date.now(),
                  name: "Mesh " + (part.meshes.length + 1),
                  filename: "",
                  file: "",
                  albedos: [],
                  materials: [
                    {
                      name: "Material base",
                      availableTextures: [
                        "Normal",
                        "Metallic",
                        "AO",
                        "Roughness",
                        "Alpha"
                      ],
                      selectedTextures: []
                    }
                  ]
                }
              ]
            }
          : part
      )
    );
  };

  const deleteMesh = (partIndex, meshIndex) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.filter((mesh, key) => key !== meshIndex)
            }
          : part
      )
    );
  };

  const addAlbedo = (meshIndex, partIndex) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.map((mesh, key) =>
                meshIndex === key
                  ? {
                      ...mesh,
                      albedos: [
                        ...mesh.albedos,
                        {
                          id: Date.now(),
                          name: "Albedo " + (mesh.albedos.length + 1),
                          type: null
                        }
                      ]
                    }
                  : mesh
              )
            }
          : part
      )
    );
  };

  const deleteAlbedo = (meshIndex, partIndex, albedoName) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.map((mesh, key) =>
                meshIndex === key
                  ? {
                      ...mesh,
                      albedos: mesh.albedos.filter(
                        (albedo) => albedo.name !== albedoName
                      )
                    }
                  : mesh
              )
            }
          : part
      )
    );
  };

  const addMaterial = (meshIndex, partIndex) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.map((mesh, key) =>
                meshIndex === key
                  ? {
                      ...mesh,
                      materials: [
                        ...mesh.materials,
                        {
                          id: Date.now(),
                          name: "Material " + mesh.materials.length,
                          availableTextures: [
                            "Normal",
                            "Metallic",
                            "AO",
                            "Roughness",
                            "Alpha"
                          ],
                          selectedTextures: []
                        }
                      ]
                    }
                  : mesh
              )
            }
          : part
      )
    );
  };

  const deleteMaterial = (meshIndex, partIndex, materialIndex) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.map((mesh, key) =>
                meshIndex === key
                  ? {
                      ...mesh,
                      materials: mesh.materials.filter(
                        (material, key) => key !== materialIndex
                      )
                    }
                  : mesh
              )
            }
          : part
      )
    );
  };

  const addTexture = (meshIndex, partIndex, materialIndex, type) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.map((mesh, key) =>
                meshIndex === key
                  ? {
                      ...mesh,
                      materials: mesh.materials.map((material, key) => {
                        if (materialIndex === key) {
                          const filteredAvailableTextures = material.availableTextures.filter(
                            (texture) => texture !== type
                          );

                          return {
                            ...material,
                            availableTextures: filteredAvailableTextures,
                            selectedTextures: [
                              ...material.selectedTextures,
                              {
                                id: Date.now(),
                                name: type + " texture",
                                type: type,
                                file: "",
                                filename: ""
                              }
                            ]
                          };
                        } else {
                          return material;
                        }
                      })
                    }
                  : mesh
              )
            }
          : part
      )
    );
  };

  const deleteTexture = (meshIndex, partIndex, materialIndex, type) => {
    setProductParts(
      productParts.map((part, key) =>
        key === partIndex
          ? {
              ...part,
              meshes: part.meshes.map((mesh, key) =>
                meshIndex === key
                  ? {
                      ...mesh,
                      materials: mesh.materials.map((material, key) => {
                        if (materialIndex === key) {
                          const filteredSelectedTextures = material.selectedTextures.filter(
                            (texture) => texture.name !== type
                          );

                          return {
                            ...material,
                            availableTextures: [
                              ...material.availableTextures,
                              type
                            ],
                            selectedTextures: filteredSelectedTextures
                          };
                        } else {
                          return material;
                        }
                      })
                    }
                  : mesh
              )
            }
          : part
      )
    );
  };

  const pushData = () => {
    console.log(
      JSON.parse(
        JSON.stringify({
          name: productName,
          product_id: productID ? productID : undefined,
          parts: productParts.map((part) => ({
            name: part.name,
            meshes: part.meshes.map((mesh) => ({
              name: mesh.name,
              file: mesh.file,
              filename: mesh.filename,
              albedos: mesh.albedos.map((albedo) => ({
                name: albedo.name,
                image: albedo.file,
                ...(albedo.type === "image"
                  ? { filename: albedo.filename }
                  : { hex: albedo.color })
              })),
              material: mesh.materials.map((material) => ({
                name: material.name,
                type: "basematerial",
                texture: material.selectedTextures.map((texture) => ({
                  name: texture.name,
                  type: texture.type.toLowerCase(),
                  image: texture.file,
                  filename: texture.filename
                }))
              }))
            }))
          }))
        })
      )
    );
  };

  return (
    <div className="product-wizard">
      <div className="components-holder">
        <div className="head">
          <div className="title">
            {editorMode.field === "prod-name" ? (
              <div className="editor">
                <input
                  type="text"
                  value={editorMode.inputValue ?? productName}
                  onChange={(e) => {
                    if (e.target.value.replace(/[a-zA-Z0-9\s]+/g, "") === "") {
                      setEditorMode({
                        ...editorMode,
                        inputValue: e.target.value
                      });
                    }
                    setAvailableProducts(
                      productsListing.search(e.target.value)
                    );
                  }}
                />
                {availableProducts.length > 0 ? (
                  <div className="prods-suggestion">
                    <div className="head">Pick an existing product</div>
                    {availableProducts.map((prod) => (
                      <li
                        onClick={() => {
                          setProductName(prod.item.product.name);
                          setProductID(prod.item.product.id);
                          setAvailableProducts([]);
                          closeEditor();
                        }}
                      >
                        {prod.item.product.name}
                      </li>
                    ))}
                  </div>
                ) : null}
                <i className="fas fa-times" onClick={() => closeEditor()}></i>
                <i
                  className="fas fa-check"
                  onClick={() => {
                    setProductName(editorMode.inputValue);
                    setProductID();
                    closeEditor();
                  }}
                ></i>
              </div>
            ) : (
              <p className="view">
                {productName}{" "}
                <i
                  className="fas fa-pen"
                  onClick={() =>
                    setEditorMode({
                      ...editorMode,
                      field: "prod-name"
                    })
                  }
                ></i>
              </p>
            )}
          </div>
          <div className="upload-btn" onClick={() => pushData()}>
            Upload
          </div>
        </div>
        {productParts.map((part, partIndex) => (
          <AnimateHeight
            className="part"
            height={minimizedPartWindow.includes(part.name) ? 70 : "auto"}
            duration={500}
            key={part.id}
          >
            <div className="head">
              <p className="title">
                {editorMode.partIndex === partIndex &&
                editorMode.field === "part" ? (
                  <div className="editor">
                    <input
                      type="text"
                      value={editorMode.inputValue ?? part.name}
                      onChange={(e) =>
                        e.target.value.replace(/[a-zA-Z0-9\s]+/g, "") === ""
                          ? setEditorMode({
                              ...editorMode,
                              inputValue: e.target.value
                            })
                          : null
                      }
                    />
                    <i
                      className="fas fa-times"
                      onClick={() => closeEditor()}
                    ></i>
                    <i
                      className="fas fa-check"
                      onClick={(filename, base64) => {
                        setProductParts((parts) =>
                          update(parts, {
                            [partIndex]: {
                              name: { $set: editorMode.inputValue }
                            }
                          })
                        );

                        closeEditor();
                      }}
                    ></i>
                  </div>
                ) : (
                  <div className="view">
                    {part.name}{" "}
                    <i
                      className="fas fa-pen"
                      onClick={() =>
                        setEditorMode({
                          ...editorMode,
                          partIndex: partIndex,
                          field: "part"
                        })
                      }
                    ></i>
                  </div>
                )}
              </p>

              <div className="window-actions">
                {minimizedPartWindow.includes(part.name) ? (
                  <i
                    className="fas fa-plus"
                    title="Maximize"
                    onClick={() =>
                      setMinimizedPartWindow(
                        minimizedPartWindow.filter(
                          (entry) => entry !== part.name
                        )
                      )
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-minus"
                    title="Minimize"
                    onClick={() =>
                      setMinimizedPartWindow([
                        ...minimizedPartWindow,
                        part.name
                      ])
                    }
                  ></i>
                )}
                {partIndex === 0 ? null : (
                  <i
                    className="fas fa-times delete-entry"
                    onClick={() => deletePart(partIndex)}
                    title="Delete"
                  ></i>
                )}
              </div>
            </div>
            {part.meshes.map((mesh, meshIndex) => (
              <Mesh
                mesh={mesh}
                updateMesh={(filename, base64) =>
                  setProductParts((parts) =>
                    update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            filename: { $set: filename },
                            file: { $set: base64 }
                          }
                        }
                      }
                    })
                  )
                }
                updateMeshName={(name) =>
                  setProductParts((parts) =>
                    update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            name: { $set: name }
                          }
                        }
                      }
                    })
                  )
                }
                updateAlbedo={(albedoIndex, filename, base64, hex) => {
                  setProductParts((parts) =>
                    update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            albedos: {
                              [albedoIndex]: {
                                $merge: hex
                                  ? { color: hex }
                                  : {
                                      filename: { $set: filename },
                                      file: { $set: base64 }
                                    }
                              }
                            }
                          }
                        }
                      }
                    })
                  );
                }}
                updateAlbedoType={(albedoIndex, albedoType) => {
                  setProductParts((parts) =>
                    update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            albedos: {
                              [albedoIndex]: {
                                type: { $set: albedoType }
                              }
                            }
                          }
                        }
                      }
                    })
                  );
                }}
                updateAlbedoName={(albedoIndex, name) => {
                  setProductParts((parts) => {
                    console.log(
                      parts,
                      update(parts, {
                        [partIndex]: {
                          meshes: {
                            [meshIndex]: {
                              albedos: {
                                [albedoIndex]: {
                                  name: { $set: name }
                                }
                              }
                            }
                          }
                        }
                      })
                    );
                    return update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            albedos: {
                              [albedoIndex]: {
                                name: { $set: name }
                              }
                            }
                          }
                        }
                      }
                    });
                  });
                }}
                updateTexture={(
                  materialIndex,
                  textureIndex,
                  filename,
                  base64
                ) => {
                  setProductParts((parts) =>
                    update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            materials: {
                              [materialIndex]: {
                                selectedTextures: {
                                  [textureIndex]: {
                                    filename: { $set: filename },
                                    file: { $set: base64 }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    })
                  );
                }}
                updateTextureName={(materialIndex, textureIndex, name) => {
                  setProductParts((parts) =>
                    update(parts, {
                      [partIndex]: {
                        meshes: {
                          [meshIndex]: {
                            materials: {
                              [materialIndex]: {
                                selectedTextures: {
                                  [textureIndex]: {
                                    name: { $set: name }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    })
                  );
                }}
                minimizedMeshWindow={minimizedMeshWindow}
                setMinimizedMeshWindow={setMinimizedMeshWindow}
                addAlbedo={addAlbedo}
                addMaterial={addMaterial}
                addTexture={addTexture}
                deleteMesh={deleteMesh}
                deleteAlbedo={deleteAlbedo}
                deleteMaterial={deleteMaterial}
                deleteTexture={deleteTexture}
                setEditorMode={setEditorMode}
                editorMode={editorMode}
                closeEditor={closeEditor}
                partIndex={partIndex}
                meshIndex={meshIndex}
                key={mesh.id}
              />
            ))}
            <div className="add-btn" onClick={() => addMesh(partIndex)}>
              <i className="fas fa-plus"></i>
              <p>Add Mesh</p>
            </div>
          </AnimateHeight>
        ))}
        <div className="add-btn" onClick={() => addPart()}>
          <i className="fas fa-plus"></i>
          <p>Add Part</p>
        </div>
      </div>
    </div>
  );
}

export default ProductWizard;
