import React, { useEffect } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Http from "axios";
import "./styles/App.css";

import SideNav from "./components/shared/SideNav";
import TopNav from "./components/shared/TopNav";

import {
  tokenStatus,
  updateAccount,
  resetAccount,
} from "./actions/UserActions";

import { PrivateRoute, PublicRoute, safeParseInt } from "./Routes";
import store from "./store";

import Profile from "./pages/Profile";
import Login from "./pages/Auth";
import Products from "./pages/Products";
import ProductWizard from "./pages/ProductWizard";
import Companies from "./pages/Companies";
import Users from "./pages/Users";
import SingleProduct from "./pages/SingleProduct";
import ProductHotspots from "./pages/ProductHotspots";
import PreviewGenerator from "./pages/PreviewGenerator";
import Tools from "./pages/Tools";
import Docs from "./pages/Docs";

function App() {
  const tokenExpiration = useSelector(({ User }) => User.accessTokenExpiration);
  useSelector((data) => console.log(data));
  const user = useSelector(({ User }) => User.user);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(tokenExpiration, Date.now());
  }, [tokenExpiration, user]);

  const tokenStatusChecker = async () => {
    try {
      if (user) {
        dispatch(tokenStatus(user.id));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        updateAccount({
          user: null,
          accessToken: null,
          accessTokenExpiration: 0,
          refreshToken: null,
        })
      );
    }
  };

  useEffect(() => {
    tokenStatusChecker();
  }, [user]);

  const account = store.getState().User;
  const expiredToken =
    !account?.accessTokenExpiration ||
    Date.now() > safeParseInt(account?.accessTokenExpiration, 10);

  return (
    <div className="App">
      <div
        className={
          "view-holder " +
          (!expiredToken && account?.user ? "logged-in" : "logged-out")
        }
      >
        <TopNav />
        <SideNav />
        <div className="main-page">
          <Switch>
            <PrivateRoute exact path="/products" component={Products} />
            <PrivateRoute exact path="/products/:search" component={Products} />
            <PrivateRoute exact path="/companies" component={Companies} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/tools" component={Tools} />
            <PrivateRoute exact path="/tools/docs" component={Docs} />
            <PrivateRoute
              exact
              path="/tools/preview-generator"
              component={PreviewGenerator}
            />
            <PrivateRoute exact path="/new-product" component={ProductWizard} />
            <PublicRoute exact path="/login" component={Login} />
            <Route
              exact
              path="/tools/hotspots/:id"
              component={ProductHotspots}
            />
            <Route exact path="/tools/hotspots" component={ProductHotspots} />
            <PrivateRoute exact path="/:id" component={SingleProduct} />
            <Redirect to="/products" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

let refreshRequest = null;

const safeParse = (data) => {
  try {
    const parsedJSON = JSON.parse(data);
    return parsedJSON;
  } catch (err) {
    return false;
  }
};

const refreshExpiredToken = async (refreshToken, expirationDate) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const user = safeParse(localStorage.getItem("user"));

    if (Date.now() >= safeParseInt(expirationDate, 10)) {
      console.log("Refreshing Expired token!", Date.now(), expirationDate);
      const { data } = await Http.post(
        `https://testapi.baetes.com/api/v1/accounts/${user.id}/tokens/refresh`,
        {},
        {
          headers: {
            "X-Baetes-Refresh-Token": refreshToken,
          },
        }
      );
      console.log("Refreshed expired token!", data);
      localStorage.setItem("accessToken", data.token);
      localStorage.setItem(
        "accessTokenExpiration",
        Date.now() + data.expires_in * 1000
      );

      return data.token;
    }

    if (!refreshToken) {
      console.error("User is not logged in!");
      return false;
    }

    return accessToken;
  } catch (err) {
    resetAccount()(store.dispatch);
  }
};

const throttleRefreshToken = async (refreshToken, expirationDate) => {
  try {
    if (!refreshRequest) {
      refreshRequest = refreshExpiredToken(refreshToken, expirationDate);
    }

    const data = await refreshRequest;
    refreshRequest = null;

    return data;
  } catch (err) {
    refreshRequest = null;
    throw err;
  }
};

Http.interceptors.request.use(async (config) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const accessTokenExpiration = localStorage.getItem("accessTokenExpiration");
    const user = safeParse(localStorage.getItem("user"));

    if (
      accessToken &&
      user &&
      !config.headers["X-Baetes-Access-Token"] &&
      !config.url.includes("/tokens/refresh") &&
      !config.url.includes("/accounts/login")
    ) {
      const token = await throttleRefreshToken(
        refreshToken,
        accessTokenExpiration
      );

      config.headers.common["X-Baetes-Access-Token"] = `${token}`;
    }

    return config;
  } catch (err) {
    console.error(err);
    return config;
  }
});

Http.interceptors.response.use(null, (error) => {
  if (error.response?.data?.message === "Invalid Token") {
    resetAccount()(store.dispatch);
  }

  return Promise.reject(error);
});

export default withRouter(App);
