import React from "react";
import "../styles/App.css";
import Login from "../components/shared/Login";

function Auth() {
  return (
    <div className="auth-panel">
      <Login />
    </div>
  );
}

export default Auth;
