import Http from "axios";

export const ACTIONS = {
  SET_ACCOUNTS: "SET_ACCOUNTS",
  SET_ROLES: "SET_ROLES",
};

export const getAllAccounts = (page) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/?page=${page}`,
      {
        withCredentials: false,
      }
    );
    dispatch({
      data: data,
      type: ACTIONS.SET_ACCOUNTS,
    });
  } catch (err) {
    throw err;
  }
};

export const createAccount = (
  name,
  username,
  email,
  phone,
  companyID,
  role,
  password
) => async (dispatch) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/create/`,
      {
        username,
        password,
        email,
        phone_number: phone,
        name,
        company_id: companyID,
        permission_id: role,
      },
      {
        withCredentials: false,
      }
    );

    getAllAccounts()(dispatch);
  } catch (err) {
    throw err;
  }
};

export const deleteAccount = (id) => async (dispatch) => {
  try {
    await Http.post(`https://testapi.baetes.com/api/v1/accounts/${id}/delete`, {
      withCredentials: false,
    });

    getAllAccounts()(dispatch);
  } catch (err) {
    throw err;
  }
};

export const editAccountDetails = (id, field, value) => async (dispatch) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/update`,
      {
        [field]: value,
      },
      {
        withCredentials: false,
      }
    );

    getAllAccounts()(dispatch);
  } catch (err) {
    throw err;
  }
};

export const changePassword = (id, oldpass, newpass) => async (dispatch) => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/update-password`,
      {
        old_password: oldpass,
        new_password: newpass,
      },
      {
        withCredentials: false,
      }
    );
  } catch (err) {
    throw err;
  }
};

export const editAllAccountDetails = (id, data) => async () => {
  try {
    await Http.post(
      `https://testapi.baetes.com/api/v1/accounts/${id}/update`,
      data,
      {
        withCredentials: false,
      }
    );
  } catch (err) {
    throw err;
  }
};

export const getAvailableRoles = (userID, companyID) => async (dispatch) => {
  try {
    const { data } = await Http.get(
      `https://testapi.baetes.com/api/v1/accounts/${userID}/role/${companyID}/list`,
      {
        withCredentials: false,
      }
    );
    dispatch({
      data: data,
      type: ACTIONS.SET_ROLES,
    });
    return data;
  } catch (err) {
    throw err;
  }
};
