import { combineReducers } from "redux";
import User from "./User";
import Products from "./Products";
import Companies from "./Companies";
import Accounts from "./Accounts";

const reducers = combineReducers({
  User,
  Products,
  Companies,
  Accounts,
});

export default reducers;
